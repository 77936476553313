import React, {useState, useEffect} from 'react'
import {
    Modal,
    Button
} from 'react-bootstrap'
import axios from 'axios'

function ModalDeleteSubCategory(props) {

    const [showModal,       setshowModal]  = useState(false);

    const [sending, setsending]                         = useState(false);
    const [successmessage, setsuccessmessage]           = useState('');
    const [errormessage, seterrormessage]               = useState('');

    const [category,  setcategory]                      = useState(null);

    useEffect(() => {
        if(props.show === true){
            if(!showModal){
                setshowModal(props.show);
                setsending(false);
                setsuccessmessage('');
                seterrormessage('');
                setcategory(null);

                if(props.category){
                    console.log('Actualizando categoria');
                    setcategory(props.category);
        
                    setsending(false);
                    setsuccessmessage('');
                    seterrormessage('');
                } 
            }
        }else{
            setshowModal(props.show);
        }
    });

    const deleteFolder = (e) => {
        let url = '/filemanager/categories/delete/'+category.id+'/';

        setsending(true);
        setsuccessmessage('');
        seterrormessage('');

        axios({
            url: url,
            method: 'delete',
        }).then((res) => {
            console.log(res.datadata);
            setsending(false);
            setsuccessmessage('sub-categoría eliminada correctamente');
            
            //refresh
            if(props.refresh){
                props.refresh();
            }
            
            setTimeout(() => {
                props.close();
            }, 1000);

        }).catch((err) => {

            console.error(err);

            setsending(false);
            let consulterrors = err.response.data;
            console.log(consulterrors);

            if(consulterrors.hasOwnProperty("non_field_errors")){
                seterrormessage(err.response.data.non_field_errors);
            }else if(consulterrors.hasOwnProperty("detail")){
                seterrormessage(err.response.data.detail);
            }
        });
    }

    return (
        <div>
            <Modal show={showModal} onHide={props.close}>
                <Modal.Header closeButton>
                <Modal.Title>
                    <strong>
                        ¿Desea eliminar la sub-categoría 
                        <strong className="text-primary ml-2">
                            {category !== null && category.hasOwnProperty('name') 
                            ? 
                                category.name 
                            : 
                                ''
                            }
                        </strong>?
                    </strong>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(successmessage !== '') &&
                        <div className="mb-3 alert alert-success">
                            <p className="mb-0 font-weight-bold">{successmessage}</p>
                        </div>
                    }
                    {(errormessage !== '') &&
                        <div className="mb-3 alert alert-danger">
                            <p className="mb-0 font-weight-bold">{errormessage}</p>
                        </div>
                    }

                    <h4 className="font-weight-bold text-muted">
                        <strong>Nota:</strong> Esta acción no se puede deshacer.
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={sending} onClick={(e) => deleteFolder(e)} variant="primary" type="button" >
                        {(sending) ? <span><i className="fa fa-spinner fa-spin mr-2"></i>Cargando</span> : <span><i className="fa fa-ckeck mr-2"></i>Eliminar</span>}
                    </Button>
                    <Button variant="light" onClick={props.close}>
                        <i className="fa fa-times mr-2"></i>Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalDeleteSubCategory;
