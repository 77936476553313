import React, {useState, useEffect} from 'react'
import {withRouter} from "react-router-dom"
import axios from "axios"
import Loader from '../../../helpers/Loader';

function NewPassword(props) {

    const [loading, setloading] = useState(true);
    const [search,  setsearch]  = useState(true);
    const [sending, setsending] = useState(false);

    const [errors, seterrors]   = useState({});

    const [password,                setpassword]                = useState("");
    const [passwordconfirmation,    setpasswordconfirmation]    = useState("");

    let urlValidateToken  = "/accounts/password-reset/validate_token/";
    let urlChangePassword = "/accounts/password-reset/confirm/";

    let token = props.match.params.token;

    useEffect(() => {
        if(loading){
            if(search){
                setsearch(false);
                axios({
                    method: "post",
                    url: urlValidateToken,
                    data: {
                        token
                    }
                }).then((res) => {
                    console.log(res.data);
                    if(res.data.status === "OK"){
                        setloading(false);
                    }
                }).catch((err) => {
                    console.log("error");
                    console.error(err);
                    const from  = {
                        pathname:"/login",
                        state: {errorMessage: "Ocurrio un problema: el token no existe o es invalido"}
                    };
                    props.history.push(from);
                });
            }
        }
    }, []);

    const validate = () => {
        let countErrors = 0;
        let errors = {};

        if(password === ""){
            errors.password = "Debe ingresar una contraseña";
            countErrors++;
        }else if(passwordconfirmation === ""){
            errors.passwordconfirmation = "Debe confirmar su contraseña";
            countErrors++;
        }
        
        if(password !== passwordconfirmation){
            errors.password = "Las contraseñas deben coincidir";
            countErrors++;
        }    

        if(countErrors > 0){
            seterrors(errors);
            return false;
        }else{
            return true;
        }
    }

    const changePassword = (e) => {
        e.preventDefault();
        e.stopPropagation();

        seterrors({});
        let isValid = validate();

        if(isValid){
            setsending(true);
            axios({
                method: "post",
                url: urlChangePassword,
                data: {
                    password, 
                    token
                }
            }).then((res) => {
                console.log(res.data);
                setsending(false);
                const from  = {
                    pathname:"/login",
                    state: {successMessage: "Su contraseña ha sido modificada exitosamente, inicie sesión para confirmar."}
                };
                props.history.push(from);

            }).catch((err) => {
                console.error(err);
                setsending(false);

                if(err.response.hasOwnProperty("data")){
                    seterrors(err.response.data);
                }
            });
        }
    }

    if(!loading){
        return (
            <div className="authincation h-100">
                <div className="container h-100">
                    <div className="row justify-content-center min-vh-100 align-items-center">
                        <div className="col-md-6">
                            <div className="authincation-content">
                                <div className="row no-gutters">
                                    <div className="col-xl-12">
                                        <div className="auth-form">
                                            <h4 className="text-center font-weight-bold h2 mb-4">
                                                Escriba la nueva contraseña
                                            </h4>
                                            <p className='small'>
                                                Por favor introduzca su contraseña dos veces para verificar que la ha escrito correctamente
                                            </p>
                                            <form action="" onSubmit={(e) => changePassword(e)}>
                                                <div className="form-group">
                                                    <label htmlFor="pass1"><strong>Contraseña nueva</strong></label>
                                                    <input id="pass1" value={password} onChange={(e) => setpassword(e.target.value)} type="password" className="form-control" placeholder="Contraseña nueva" />
                                                    {errors.hasOwnProperty("password") &&
                                                        <div className="help-block">
                                                            <p className="mb-0 small text-danger py-2 font-weight-bold">
                                                                {errors.password}
                                                            </p>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="pass2"><strong>Contraseña nueva confirmación</strong></label>
                                                    <input id="pass2" value={passwordconfirmation} onChange={(e) => setpasswordconfirmation(e.target.value)} type="password" className="form-control" placeholder="Contraseña nueva confirmación" />
                                                    {errors.hasOwnProperty("passwordConfirmation") &&
                                                        <div className="help-block">
                                                            <p className="mb-0 small text-danger py-2 font-weight-bold">
                                                                {errors.passwordConfirmation}
                                                            </p>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="text-center pt-3">
                                                    <button disabled={sending} type="submit" className="btn btn-primary btn-block">
                                                        {(!sending) 
                                                            ? <span>Cambiar contraseña</span> 
                                                            : <span><i className="fa fa-spin fa-spinner"></i></span>
                                                        }
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }else{
        return (
            <div className="authincation h-100">
                <Loader />
            </div>
        )
    }
}

export default withRouter(NewPassword);
