import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Loader from '../../../helpers/Loader';
import {Link} from 'react-router-dom'
import InlineLoader from '../../../helpers/InlineLoader';

function Tags() {
    const [loading, setloading] = useState(true);
    const [search, setsearch] = useState(true);
    const [list, setlist] = useState(null);

    const getData = () => {
      if(search){
        setsearch(false);

        let url = '/filemanager/tags-in-use/';

        axios({
          method: 'GET',
          url
        }).then((res) => {

          console.log(res.data);
          setlist(res.data);
          setloading(false);

        }).catch((err) => {

          console.error(err);

        });
      }
    }


    useEffect(() => {
      if(loading){
        getData();
      }
    }, []);


    return (
        <div className="mb-5">
        <div className="row mb-5 align-items-center">
          <div className="col-lg-12">
            <div className="card m-0 ">
              <div className="card-body py-3 py-md-2">
                <div className="row align-items-center">
                  <div className="col-md-5 mb-md-0">
                    <div className="media align-items-end">
                      <div className="media-body ml-1">
                        <h3 className="mb-0 text-black font-w600 fs-20">Todas las Etiquetas</h3>
                      </div>
                    </div>
                  </div>
                </div>							
              </div>
            </div>
          </div>
        </div>

        {(loading) 
          ?
          <InlineLoader />
          :
          <div className="row">
          <div className="col-xl-12">	
            <div className="card">
              {(list !== null) &&
                <div className="card-body">
                  <div className="bootstrap-badge">
                    {(list.length > 0 && list.map((item, key) => {
                      if(item.id === null){
                        return (
                          <Link key={key} to={'/etiqueta/'+0} className="badge mr-2 badge-rounded badge-danger mb-2">
                            Sin etiquetas
                          </Link>
                        )
                      }else{
                        return (
                            <Link key={key} to={'/etiqueta/'+item.id} className="badge mr-2 badge-rounded badge-info mb-2">
                              {item.name}
                            </Link>
                        )
                      }
                    }))
                  }
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        }
      </div>
    )
}

export default Tags
