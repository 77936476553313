import React, { useEffect } from 'react'
import {Link, NavLink} from 'react-router-dom'
import {useSelector} from 'react-redux'

function Sidebar() {

    const dashboard = useSelector(state => state.dashboard);
    const session = useSelector(state => state.session);
    const userPermissions = session.userData.permissions;

    const categories = dashboard.categories;

    const toggleSubmenu = (e, idSubmenu) => {
      e.preventDefault();
      let thisElement = e.target;
      let liParent = thisElement.parentNode;
      let isactive = liParent.classList.contains('mm-active');
      let isparent = thisElement.classList.contains('content-parent-submenu');
      let submenu = document.querySelector(idSubmenu);

      if(!isactive){

        if(isparent){
          //si es un submenu padre cierra todos los submenues abiertos
          let elementActive = document.querySelector('#menu>li.mm-active>a');
          if(elementActive !== null){
            closeSubMenu(elementActive.getAttribute("href"));
          } 
        }

        liParent.classList.add('mm-active');
        thisElement.setAttribute("aria-expanded", "true");
        submenu.classList.add('mm-show');
        //console.log(submenu);
        
      }else{
        
        liParent.classList.remove('mm-active');
        thisElement.setAttribute("aria-expanded", "false");
        
        submenu.classList.remove('mm-show');
      }
    }

    const closeSubMenu = (id) => {
      let submenu = document.querySelector(id);
      let liParent = submenu.parentNode;
      let isactive = liParent.classList.contains('mm-active');

      if(isactive){
        liParent.classList.remove('mm-active');
        liParent.children[0].setAttribute("aria-expanded", "false");
        submenu.classList.remove('mm-show');
      }
    }

    const manageResponsiveSidebar = () => {
      console.log('resizing');
      
        const innerWidth = window.innerWidth;
        const container  = document.getElementById('container-app');

        if(innerWidth < 1200) {
            container.setAttribute("data-layout", "vertical");
            container.setAttribute("data-container", "wide");
        }

        if(innerWidth > 1200) {
            container.setAttribute("data-sidebar-style", "full");
        }
    
        if(innerWidth > 767 && innerWidth < 1200) {
            container.setAttribute("data-sidebar-style", "mini");
        }
    
        if(innerWidth < 768) {
            container.setAttribute("data-sidebar-style", "overlay");
        }
      
    }
  
    useEffect(() => {
      window.addEventListener( 'resize',  manageResponsiveSidebar);
      let btnCloseSearchMobile  = document.getElementById("btnCloseSearchMobile");
      let toggleSearchMobile    = document.getElementById("toggle-search-mobile");

      btnCloseSearchMobile.addEventListener("click", function() {
        let body                = document.getElementsByTagName("body")[0];

        if(body.classList.contains("show-search-mobile")){
          body.classList.remove("show-search-mobile");
        }else{
          body.classList.add("show-search-mobile");
        }
      });


      toggleSearchMobile.addEventListener("click", function() {
        let body                = document.getElementsByTagName("body")[0];

        if(body.classList.contains("show-search-mobile")){
          body.classList.remove("show-search-mobile");
        }else{
          body.classList.add("show-search-mobile");
        }
      });

      if (document.readyState === "complete") {
        manageResponsiveSidebar();
      }
    }, []);

    return (
        <div className="deznav">
          <div 
          className="deznav-scrollss content-scrollbar pss"
          >
            
              <ul className="metismenu" id="menu">

                <li>
                    <a onClick={(e) => toggleSubmenu(e, '#submenu-dashboard')} className="has-arrow content-parent-submenu content-submenu ai-icon" href="#submenu-dashboard" aria-expanded="false">
                        <i className="flaticon-381-networking" />
                        <span className="nav-text">Dashboard</span>
                    </a>
                    <ul 
                        id="submenu-dashboard"
                        aria-expanded="false" 
                        className="mm-collapse" 
                        style={{height: 'auto'}}
                    >
                        <li>
                            <NavLink to="/dashboard" activeClassName="active">Escritorio</NavLink>
                        </li>
                        {userPermissions.has_drive_access &&
                          <li>
                              <NavLink to="/unidad" activeClassName="active">Mi unidad</NavLink>
                          </li>
                        }

                        {userPermissions.has_favorites_access &&
                          <li>
                              <NavLink to="/favoritos" activeClassName="active">Favoritos</NavLink>
                          </li>
                        }
                        
                        {userPermissions.has_symbolic_links_access &&
                          <li>
                              <NavLink to="/accesos-directos" activeClassName="active">Accesos directos</NavLink>
                          </li>
                        }

                        {userPermissions.has_shared_with_me_access &&
                          <li>
                            <NavLink to="/compartido-conmigo" activeClassName="active">Compartido conmigo</NavLink>
                          </li>
                        }

                        {userPermissions.has_tags_access &&
                          <li>
                              <NavLink to="/etiquetas" activeClassName="active">Etiquetas</NavLink>
                          </li>
                        }
                    </ul>
                </li>

                <li>
                  <a onClick={(e) => toggleSubmenu(e, '#submenu-archivos')} className="has-arrow content-submenu content-parent-submenu ai-icon" href="#submenu-archivos" aria-expanded="false">
                    <i className="flaticon-381-television" />
                    <span className="nav-text">Archivos</span>
                  </a>
                  <ul id="submenu-archivos" aria-expanded="false" className="mm-collapse" style={{height: 'auto'}}>
                    <li>
                      <a onClick={(e) => toggleSubmenu(e, '#submenu-categorias')} className="has-arrow content-submenu" href="#submenu-categorias" aria-expanded="false">
                        Categorías
                      </a>
                      {(categories!==null && Array.isArray(categories)) &&
                        <ul id="submenu-categorias" aria-expanded="false" className="mm-collapse">
                          {(categories.length > 0 && categories.map((item, key) => {
                            return (
                              <li key={key}>
                                <NavLink to={`/categoria/${item.id}`} activeClassName="mm-active">
                                  {item.name}
                                </NavLink>
                              </li>
                            )
                          }))}
                        </ul>
                      }
                    </li>
                  </ul>
                </li>

                {userPermissions.has_tickets_access &&
                  <li>
                      <a onClick={(e) => toggleSubmenu(e, '#submenu-support')} className="has-arrow content-parent-submenu content-submenu ai-icon" href="#submenu-support" aria-expanded="false">
                          <i className="flaticon-381-help-1" />
                          <span className="nav-text">Soporte al usuario</span>
                      </a>
                      <ul 
                          id="submenu-support"
                          aria-expanded="false" 
                          className="mm-collapse" 
                          style={{height: 'auto'}}
                      >
                          <li>
                            <NavLink to="/tickets" activeClassName="active">
                              Tickets
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/crear-ticket" activeClassName="active">
                              Crear ticket
                            </NavLink>
                          </li>
                      </ul>
                  </li>
                }
              </ul>
        </div>  
      </div>
    )
}

export default Sidebar;
