import React, {useState, useEffect} from 'react'
import Select from 'react-select'
import axios from 'axios'

function CategoriesSelect(props) {

    const [list, setList]       = useState([]);
    const [datalist, setdataList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search,  setsearch]  = useState(true);

    useEffect(() => {
        if(loading){
            if(search){
                let url = '/filemanager/all-valid-categories/';

                const getData = () => {
                    axios.get(url).then((res) => {
                        console.log();
                        let categories = res.data;
                        let newdataList = [];
                        //console.log(data);
                        let newList = [];

                        for(var i=0; i < categories.length; i++){
            
                            let thisElement = categories[i];
                
                            let formattedElement = {};
                            formattedElement.label = thisElement.name;
                            formattedElement.value = thisElement.id;
                
                            newList.push(formattedElement);
                            newdataList.push(formattedElement);
                
                            let subcats = thisElement.children;
                            let groups = {};
                            groups.label = 'subcategorías - '+thisElement.name+'';
                            groups.options = [];
                            
                            //console.log(subcats);
                            if(subcats !== undefined && Array.isArray(subcats) && subcats.length > 0){
                                for (let i = 0; i < subcats.length; i++) {
                                    let thissubcat          = subcats[i];
                                    let formattedSubCat     = {};
                
                                    formattedSubCat.label   = '-  '+thissubcat.name;
                                    formattedSubCat.value   = thissubcat.id;
                
                                    newdataList.push(formattedSubCat);
                                    groups.options.push(formattedSubCat);
                                }
                            }
                
                            newList.push(groups);
                        }            
                
                        setList(newList);
                        setdataList(newdataList);
                        setLoading(false);           

                        setList(newList);
                        setLoading(false);
                    });
                }

                setsearch(false);
                getData();
            }
        }
    }, [loading, search]);

    const handleSelect = async (selectedOption) => {
        //props.setRegion({});
        //props.setCity({});
        //console.log(selectedOption);
        //console.log(selectedOption);
        props.onChange(selectedOption); 
    };

    if(loading){
        return (
            <Select 
                placeholder="Cargando lista de categorías"  
                options={[]} 
            />
        )
    }else{

        let elementSelected = null;

        if(props.value !== undefined && props.value !== null && list.length > 0){
            let findElement = datalist.find(item => Number(item.value) === Number(props.value.value));
            elementSelected = findElement;
            //console.log(findElement);
            //console.log(props.value);
        }

        console.log("data categoria");
        console.log(props.value);
        console.log(elementSelected);

        return (
            <Select 
                isSearchable={true}
                placeholder="Seleccionar" 
                value={elementSelected}  
                onChange={handleSelect} 
                options={list} 
            />
        )
    }
}

export default CategoriesSelect
