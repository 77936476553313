import React, {useState, useEffect} from 'react'
import axios from "axios"
import Select from 'react-select'

function SelectRole(props) {

    let url = "/accounts/list-roles/";

    const [search, setsearch]               = useState(true);
    const [loading, setLoading]             = useState(true);
    const [adding, setadding]               = useState(true);

    const [data, setdata]                   = useState(null);
    const [list, setList]                   = useState([]);
    const [count, setcount]                 = useState(0);

    const [selectedRoles, setselectedRoles] = useState(props.actualList);
    
    const formatData = () => {
        let newList = [];
        let rolesInList = props.actualList === null || props.actualList === undefined ? [] : props.actualList;
        console.log("Formateando ----------------------------------");
        console.log(rolesInList);

        for(var i=0; i < data.length; i++){
            let thisElement = data[i];
            let formattedElement = {};
            formattedElement.label = thisElement.name;
            formattedElement.value = thisElement.id;

            console.log(rolesInList);

            let search = rolesInList.find(item => item.group.name === thisElement.name);
            if(search === null || search === undefined){
                newList.push(formattedElement);
            }
        }            

        setList(newList);
        setcount(count + 5);
        setLoading(false);
    }

    useEffect(() => {
        if(loading){
            if(search){
                setsearch(false);
                axios.get(url).then((res) => {
                    setdata(res.data);
                }).catch((err) => {
                    console.log(err);
                })
            }else if(!search && data !== null){
                formatData();
            }
        }else{
            if(props.actualList !== selectedRoles){
                setselectedRoles(props.actualList);
                formatData();
            }
        }
    });

    const handleSelect = async (selectedOption) => {
        props.onChange(selectedOption); 
    };

    if(loading){
        return (
            <Select 
                placeholder="Cargando Roles"  
                options={[]} 
            />
        )
    }else{
        let elementSelected = null;

        if(props.value !== undefined && props.value !== null && list.length > 0){
            let findElement = list.find(item => item.value === props.value.value);
            elementSelected = findElement;
        }

        return (
            <Select 
                isSearchable={true}
                styles={{
                    menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                placeholder="Rol" 
                value={(props.value !== undefined && props.value !== null && list.length > 0) ? elementSelected : null} 
                onChange={handleSelect} 
                options={list} 
            />
        )
    }
}

export default SelectRole
