import React, {useState, useEffect} from 'react'
import {Link, withRouter} from "react-router-dom"
import {useSelector} from "react-redux"
import axios from "axios"
import moment from "moment"
import InlineLoader from '../../../helpers/InlineLoader';
import {Modal,Button} from "react-bootstrap"

function Ticket(props) {

    let id = props.match.params.id;

    const session = useSelector(state => state.session);
    
    const name  = session.userData.first_name+" "+session.userData.last_name;
    const email = session.userData.email;

    const [data, setdata]           = useState(null);

    const [loading, setloading]     = useState(true);
    const [search, setsearch]       = useState(true);

    const [closing, setclosing]     = useState(false);

    const [sending, setsending]     = useState(false);
    const [message, setmessage]     = useState("");

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //console.log(props.history);

    const getData = () => {
        console.log(id);
        let url = `/supportsystem/tickets/${id}/messages/`;

        axios.get(url).then((res) => {
            console.log(res.data);

            setdata(res.data);
            setloading(false);

        }).catch((err) => {
            console.error(err);
        });
    }

    const sendNewMessage = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setsending(true);

        let url = `/supportsystem/tickets/${id}/messages/`;
        let datasend = {
            message
        }

        axios({
            url,
            method: "POST",
            data: datasend
        }).then((res) => {
            console.log(res.data);
            //let newMessagesList = data.messages;
            
            //console.log(newMessagesList);
            //newMessagesList.unshift(res.data);
            
            //setdata({...data, messages: newMessagesList});

            getData();            
            setmessage("");
            setsending(false);

            window.scroll({top: 200, left: 0, behavior: 'smooth'});

        }).catch((err) => {
            console.error(err);
            setsending(false);
        });
    }

    const closeTicket = (e) => {
        e.preventDefault();
        let url = `/supportsystem/tickets/${id}/closed-ticket/`;
        let datasend = {
            message
        }

        setclosing(true);

        axios({
            url,
            method: "POST",
            data: datasend
        }).then((res) => {
            console.log(res.data);

            setclosing(false);
            const from  = {
                pathname:"/tickets",
                state: {errorMessage: "El ticket ha sido cerrado"}
            };
            props.history.push(from);

        }).catch((err) => {
            console.error(err);
            setclosing(false);
        });
    }

    const closingTicket = (e) => {
        e.preventDefault();

        let url = `/supportsystem/tickets/${id}/closed-ticket/`;

        setclosing(true);

        axios({
            url,
            method: "POST"
        }).then((res) => {
            console.log(res.data);

            setclosing(false);
            const from  = {
                pathname:"/tickets",
                state: {errorMessage: "El ticket ha sido cerrado"}
            };
            props.history.push(from);

        }).catch((err) => {
            console.error(err);
            setclosing(false);
        });
    }

    useEffect(() => {
        if(loading){
            if(search){
                setsearch(false);
                getData();
            }
        }
    }, []);

    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cerrar ticket</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Esta seguro de marcar este ticket como cerrado?
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={closing} variant="primary" onClick={(e) => closingTicket(e)}>
                        {(!closing) ? "si, continuar" : <span><i className="fa fa-spin fa-spinner"></i></span>}
                    </Button>
                    <Button disabled={closing} variant="light" onClick={handleClose}>
                        no, cancelar
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="page-titles">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/tickets">
                            Tickets
                        </Link>
                    </li>
                    <li className="breadcrumb-item active">
                            Ver ticket
                    </li>
                </ol>
            </div>

            {(loading || search) &&
                <InlineLoader />
            }

            {(!loading && !search && data !== null) &&
                <div>
                    <div className="row mb-4 align-items-center">
                        <div className="col-lg-12">
                            <div className="card m-0 ">
                                <div className="card-body py-3 py-md-2">
                                    <div className="row align-items-center">
                                        <div className="col-lg-8 col-6">
                                            <h3 className="mb-0 text-black font-w600 fs-20">
                                                Ticket <strong className="text-primary">#{data.ticket.id}</strong>
                                            </h3>
                                        </div>  
                                        {data.ticket.is_open && 
                                            <div className="col-lg-4 col-6 text-right">
                                                <button 
                                                    onClick={() => handleShow()}
                                                    className="btn btn-outline-danger rounded ml-2 btn-sm px-4"
                                                >
                                                    <span>
                                                        <i className="fa fa-times mr-3"></i>Cerrar
                                                    </span>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div className="row mb-4 align-items-center">
                        <div className="col-lg-12">
                            <div className="card m-0">
                                <div className="card-body py-4 py-md-2">
                                    <h6 className="mb-4 mt-3 text-dark">
                                        <div className="row mb-2 justify-content-between">
                                            <div className="col-lg-6 col-12">
                                                <strong className="mr-3">Asunto:</strong> 
                                                {data.ticket.subject}
                                            </div>
                                            <div className="col-lg-auto col-12 text-lg-right">
                                                <strong>Estado:</strong> {data.ticket.status_name}
                                            </div>
                                        </div>
                                    </h6>

                                    <hr/>
                                    
                                    <div className="message-ticket-list py-3">
                                        {Array.isArray(data.messages) &&
                                        <ul className="list-unstyled">
                                            {(data.messages.map((item, key) => {
                                                return (
                                                    <li key={key}>
                                                        <div className="header-message text-dark bg-light">
                                                            <div className="row align-items-lg-center align-items-start px-3 py-2">
                                                                <div className="col-md-8 col-12">
                                                                        <h6 className="mb-0 text-dark">
                                                                            Publicado por <strong>{item.user.name}</strong> el {moment(item.date_created).format("DD/MM/YYYY hh:mm")}
                                                                        </h6>
                                                                </div>
                                                                <div className="col-md-4 col-12 text-md-right">
                                                                    {item.is_admin
                                                                    ?
                                                                        <span className="badge badge-success">
                                                                            Administrador
                                                                        </span>
                                                                    :
                                                                        <span className="badge badge-info">
                                                                            Usuario
                                                                        </span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="px-3 py-4 border-light" style={{border: "2px solid #ccc"}}>
                                                            <p className="h6 text-dark">
                                                                {item.message}
                                                            </p>
                                                        </div>
                                                    </li>
                                                )
                                            }))}
                                        </ul>
                                        }
                                    </div>
                                    
                                    {(data.ticket.is_open) &&
                                        <div className="px-3 mb-3 mt-4 py-4 border-light" style={{border: "2px solid #ccc"}}>
                                            <h3 className="text-dark mb-4">
                                                Responder
                                            </h3>
                                            <form onSubmit={(e) => sendNewMessage(e)} action="">
                                                <div className="row">
                                                    <div className="col-lg-6 form-group">
                                                        <label htmlFor="name">Nombre</label>
                                                        <input value={name} readOnly disabled id="name" placeholder="Nombre" type="text" className="form-control bg-light"/>
                                                    </div>
                                                    <div className="col-lg-6 form-group">
                                                        <label htmlFor="email">Correo electrónico</label>
                                                        <input value={email} readOnly disabled id="email" placeholder="Correo electrónico" type="text" className="form-control bg-light"/>
                                                    </div>
                                                    <div className="col-lg-12 form-group">
                                                        <label htmlFor="subject">Mensaje</label>
                                                        <textarea 
                                                        onChange={(e) => setmessage(e.target.value)}
                                                        value={message}
                                                        name="" 
                                                        id="" 
                                                        cols="30" 
                                                        rows="5"
                                                        placeholder="Mensaje.."
                                                        className="form-control"
                                                        ></textarea>
                                                    </div>
                                                    <div className="col-lg-12 form-group">
                                                        <div className="text-center">

                                                            <button 
                                                                disabled={sending || closing || message === ""}
                                                                className="font-weight-bold px-4 mx-2 shadow btn btn-primary">
                                                                {(sending || closing) 
                                                                ? 
                                                                    <span>
                                                                        <i className="fa fa-spin fa-spinner"></i>
                                                                    </span> 
                                                                : 
                                                                    "Enviar"
                                                                } 
                                                            </button>

                                                            <button 
                                                                type="button"
                                                                onClick={(e) => closeTicket(e)}
                                                                disabled={sending || closing || message === ""}
                                                                className="font-weight-bold px-4 mx-2 shadow btn btn-light border-dark">
                                                                {
                                                                    (sending || closing) 
                                                                ? 
                                                                    <span>
                                                                        <i className="fa fa-spin fa-spinner"></i>
                                                                    </span> 
                                                                : 
                                                                    "Enviar y Cerrar"
                                                                } 
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            }
        </div>
    )
}

export default withRouter(Ticket);
