import React, {useState, useEffect, Fragment} from 'react'
import folderImg from '../../../../../static/images/folder.png';
import {withRouter, Link} from "react-router-dom"
import {OverlayTrigger, Tooltip} from "react-bootstrap"
import axios from "axios"
import { FileIcon, defaultStyles } from 'react-file-icon';
import InlineLoader from '../../../../helpers/InlineLoader';
import {useSelector, useDispatch} from 'react-redux'
import { downloadURI, formatUrl, downloadFileFromUrl, downloadFunction } from '../../../../utils/urls';
import ModalShowFile from '../../../../helpers/ShowFile';
import DataGroupButtons from '../../../../helpers/DataGroupButtons';
import NameComponent from '../../../../helpers/Files/NameComponent';

function Folder(props) {
    let url  = "/filemanager/shared-folder/";
    let uuid = props.match.params.uuid;

    const [idsubfolder,    setidsubfolder]          = useState(props.match.params.id);
    const [folderSelected, setfolderSelected]       = useState(null);
    const [fileSelected,   setfileSelected]         = useState(null);

    const [iserror, setiserror] = useState(false);
    const [loading, setloading] = useState(true);
    const [search,  setsearch]  = useState(true);
    const [data,    setdata]    = useState(null);

    const dispatch        = useDispatch();
    const downloadList    = useSelector(state => state.dashboard.download_list);
    console.log("Lista de descargas:",downloadList);

    const getData = () => {
            url = url+uuid;
        if(idsubfolder !== null && Number(idsubfolder) >= 0){
            url = "/filemanager/shared-descendant-folder/"+uuid+"/"+idsubfolder;
        }
        axios.get(url).then((res) => {
            console.log(res.data);
            setdata(res.data);
            setloading(false);
        }).catch((err) => {
            console.error(err);
            setiserror(true);
            setloading(false);
        })
    }

    const refresh = () => {
        getData();
    }

    //show
    const [showModalShowFile, setShowModalShowFile] = useState(false);
    const handleCloseModalShowFile = () => {
        setShowModalShowFile(false);
    }
    const handleShowModalShowFile = () => {
        setShowModalShowFile(true)
    };

    const showFileNow = (file) => {
        setfileSelected(file);
        handleShowModalShowFile();
    }
    
    useEffect(() => {
        if(loading){
            if(search){
                setsearch(false);
                getData();
            }
        }else{
            if(props.match.params.id !== idsubfolder){
                //console.log(props.match.params.id);
                //console.log(idsubfolder);
                setidsubfolder(props.match.params.id);
                setsearch(true);
                setloading(true);
            }
        }
    });

    const showModal = (item, typeModal, typeItem) => {

        if(typeItem === "category"){
          //setcategorySelected(item);
  
          switch (typeModal) {
            case "rename":
              //setshowModalRenameSubCategory(true);
              break;
  
            case "move":
              //setShowModalMoveFile(true);
              break;
  
            case "share":
              //setshowModalShareSubCategory(true);
              break;
  
            case "delete":
              //setshowModalDeleteSubCategory(true);
              break;
          
            default:
              break;
          }
        }else if(typeItem === "file"){
          setfileSelected(item);
  
          switch (typeModal) {
            case "rename":
              //setShowModalRenameFile(true);
              break;
  
            case "move":
              //setShowModalMoveFile(true);
              break;
  
            case "share":
              //setShowModalShareFile(true);
              break;
  
            case "delete":
              //setShowModalDeleteFile(true);
              break;
  
            case "download":
              downloadFileFromUrl(null, formatUrl(item.file), item.name, downloadFunction, item, dispatch, downloadList)
              break;
  
            case "show":
              setShowModalShowFile(true);
              break;
  
            case "editStatus":
              //setShowModalStatus(true);
            break;
  
            case "editCategory":
              //setShowModalEditCategory(true);
            break;
          
            default:
              break;
          }
        }
    }


    if(iserror){
        //error
        return (
            <div>
                <h1 className="font-weight-bold mb-3 h3 mb-3">
                    Carpeta compartida
                </h1>
                <div className="card">
                    <div className="card-body">
                        <h3 className="text-center mb-0">
                            <i className="fa fa-exclamation-triangle mr-3"></i> directorio no encontrado.
                        </h3>
                    </div>
                </div>
            </div>
        )
    }else{
        if(loading){
            //cargando
            return (
                <div>
                    <InlineLoader />
                </div>
            )
        }else{
            return (
                <div>
                    {
                    
                    (Array.isArray(data.breadcrumbs) && data.breadcrumbs.length > 0 && data.breadcrumbs && data.breadcrumbs !== null) 
                    ?
                        <div className="page-titles">
                            <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to={"/shared-folder/"+uuid+"/"}>
                                    Carpeta compartida
                                </Link>
                            </li>
                            {data.breadcrumbs && data.breadcrumbs.map((item, key) => {
                                if(data.breadcrumbs.indexOf(item) > 0){
                                    return (
                                        <li key={key} className="breadcrumb-item">
                                            <Link to={"/shared-folder/"+uuid+"/subfolder/"+item.id}>
                                                {item.name}
                                            </Link>
                                        </li>
                                    )
                                }else{
                                    return ""
                                }
                            })}
                            </ol>
                        </div>
                    :
                        ""
                        
                    }
                    <h1 className="font-weight-bold mb-3 h3">
                        Carpeta compartida
                    </h1>
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-lg-auto">
                                    <h5>
                                        <i className="fa fa-folder mr-2"></i> {data.breadcrumbs[data.breadcrumbs.length - 1].name}
                                    </h5>
                                </div>
                                <div className="col-lg-auto">
                                    <h5 className="text-muted">
                                        Creador: {data.owner.name} - (<i className="fa fa-envelope mr-2"></i>{data.owner.email})
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">	
                                    <div className="">
                                        <div>
                                            <div className="table-responsive table-hover fs-14">
                                                <div id="example5_wrapper" className="dataTables_wrapper no-footer">
                                                    <table className="table display mb-4 dataTablesCard fs-14 dataTable no-footer" id="example5" role="grid" aria-describedby="example5_info">
                                                        <thead>
                                                        <tr role="row">
                                                            <th style={{width: 'auto'}} 
                                                            className="sorting_asc" 
                                                            tabIndex={0} 
                                                            aria-controls="example5" 
                                                            rowSpan={1} 
                                                            colSpan={1} 
                                                            aria-sort="ascending" 
                                                            aria-label="Archivo: activate to sort column descending">
                                                            Nombre
                                                            </th>
                                                            <th 
                                                            className="d-none d-lg-table-cell sorting" 
                                                            tabIndex={0} 
                                                            aria-controls="example5" 
                                                            rowSpan={1} 
                                                            colSpan={1} 
                                                            aria-label="Categoría: activate to sort column ascending" 
                                                            style={{width: '200px'}}>
                                                            Categoría
                                                            </th>
                                                            <th 
                                                            className="text-center sorting" 
                                                            tabIndex={0} 
                                                            aria-controls="example5" 
                                                            rowSpan={1} 
                                                            colSpan={1} 
                                                            aria-label="Acción: activate to sort column ascending" 
                                                            style={{width: '568px'}}>
                                                            Acción
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                            { 
                                                                (data.folders && Array.isArray(data.folders) && data.folders.length === 0) &&
                                                                (data.files && Array.isArray(data.files) && data.files.length === 0) &&
                                                                <Fragment>
                                                                    <tr>
                                                                        <td colSpan="4" className="text-center">
                                                                        Sin archivos encontrados
                                                                        </td>
                                                                    </tr>
                                                                </Fragment>
                                                            }

                                                            {
                                                            
                                                            (data.folders && Array.isArray(data.folders)) &&
                                                                <Fragment>
                                                                {(data.folders.length > 0 && data.folders.map((item, key) => {
                                                                    return (
                                                                    <tr key={key}>
                                                                        <td>
                                                                        <Link 
                                                                            to={"/shared-folder/"+uuid+"/subfolder/"+item.id}
                                                                            //onClick={() => getDataFolder(item.id)}
                                                                            style={{width: 'auto'}} 
                                                                            className="media align-items-center item-click"
                                                                        >
                                                                            <img 
                                                                                className="img-fluid rounded mr-3 d-xl-inline-block" 
                                                                                width={70} 
                                                                                src={folderImg} 
                                                                                alt="Carpeta" 
                                                                            />
                                                                            <div className="media-body">
                                                                            <h4 className="text-black font-w600 mb-1 wspace-no">
                                                                                {item.name}
                                                                            </h4>
                                                                            </div>
                                                                        </Link>
                                                                        </td>
                                                                        <td className="text-center">--</td>
                                                                        <td className="text-center">--</td>
                                                                    </tr>
                                                                    )
                                                                }))}
                                                                </Fragment>
                                                            }

                                                            {(data.files && Array.isArray(data.files)) &&
                                                                <Fragment>
                                                                    {(data.files.length > 0 && data.files.map((item, key) => {

                                                                    let filename = item.name.split('.');
                                                                    let formattext = filename[filename.length - 1].toLowerCase();
                                                                    //console.log(formattext);

                                                                    return (
                                                                        <tr key={key}>
                                                                        <td>
                                                                            <div 
                                                                                style={{width: 'auto'}} 
                                                                                className="media align-items-center"
                                                                            >
                                                                                <div style={{minWidth: "300px"}} className={(item.is_symbolic_link ? "is-symbolic-item" : "") + " d-flex align-items-center"}>
                                                                                    <div>
                                                                                        {(item.thumbnail !== null && item.thumbnail !== '') 
                                                                                            ?
                                                                                            <img 
                                                                                                className="img-fluid rounded mr-3 d-xl-inline-block" 
                                                                                                width={70} 
                                                                                                src={formatUrl(item.thumbnail)} 
                                                                                                alt="Carpeta" 
                                                                                            />
                                                                                            :
                                                                                            <div style={{ width: "70px", marginRight: "15px" }}>
                                                                                                <div style={{ width: "70px"}}></div>
                                                                                                <FileIcon radius={12} extension={formattext} {...defaultStyles[formattext]} />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="media-body">
                                                                                        <NameComponent
                                                                                            item={item} 
                                                                                            id={item.id} 
                                                                                            name={item.name} 
                                                                                            // status={item.status} 
                                                                                            // status_name={item.status_name} 
                                                                                            // selectItem={setfileSelected}
                                                                                            // showModalStatus={() => setShowModalStatus(true)}
                                                                                            //showBtn={item.permissions.can_change_status}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {item.category !== null ? item.category.breadcrumb : "--"}
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex align-items-start justify-content-center">
                                                                                
                                                                                <DataGroupButtons
                                                                                    page="shared-folder"
                                                                                    type="file"
                                                                                    //userPermissions={userPermissions} 
                                                                                    id={`btnc-shared-folder-unidad-${item.id}`} 
                                                                                    item={item}
                                                                                    permissions={item.permissions}
                                                                                    //setFolder={setfolderSelected}
                                                                                    //setFile={setfileSelected}
                                                                                    showModal={showModal}
                                                                                /> 
                                                                                
                                                                            </div>
                                                                        </td>
                                                                        </tr>
                                                                    )
                                                                    }))}    
                                                                </Fragment>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ModalShowFile refresh={refresh} file={fileSelected} show={showModalShowFile}  close={handleCloseModalShowFile} />
                </div>
            )
        }
    }
}

export default withRouter(Folder);
