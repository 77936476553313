import React, {useState} from 'react'
import { useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom'
import { handleLogin } from '../../../../store/session/actions';
import Logo from '../../../helpers/Logo'
import axios from 'axios'

function Login(props) {

    const [errorMessage,    seterrorMessage]        = useState("");

    const [username,        setusername]            = useState('');
    const [password,        setpassword]            = useState('');

    const [rememberme,      setrememberme]          = useState(false);
    const [sending,         setsending]             = useState(false);
    const [showPsw,         setShowPsw]             = useState(false);

    const [errors,          seterrors]              = useState({});

    const dispatch = useDispatch();

    const validate = () => {
        let counterrors = 0;
        let errorslist = {};

        if(username === ''){
            errorslist.username = 'Debe ingresar un nombre de usuario';
            counterrors++;
        }else if(username.length > 150){
            errorslist.username = 'El nombre de usuario es demasiado largo';
            counterrors++;
        }

        if(password === ''){
            errorslist.password = 'Debe ingresar una contraseña';
            counterrors++;
        }else if(password.length > 40){
            errorslist.password = 'Contraseña es demasiado larga';
            counterrors++;
        }

        if(counterrors > 0){
            seterrors(errorslist);
            return false;
        }

        return true;
    }

    const goLogin = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        seterrors(null);
        seterrorMessage("");
        let isValid = validate();
        

        if(isValid){
            //validando formulario
            setsending(true);

            let url = '/auth/token/';

            axios({
                url,
                method: 'post',
                data: {
                    email: username,
                    password: password
                }
            }).then((res) => {
                console.log(res.data);
                localStorage.setItem('token', res.data.access);
                axios.defaults.headers.common = {'Authorization': `Bearer ${res.data.access}`}

                dispatch(handleLogin());
                setsending(false);

            }).catch((err) => {
                console.error(err);
                setsending(false);

                if(err.response){
                    if(err.response.data.hasOwnProperty("detail")){
                        seterrorMessage(err.response.data.detail);
                    }
                }
            })
        }
    }
    
    console.log(props.location);

    return (
        <div className="authincation">
        <div className="container h-100">
            <div className="row justify-content-center min-vh-100 align-items-center">
                {(typeof props.location.state === 'object' && props.location.state.hasOwnProperty("errorMessage")) &&
                    <div className="col-lg-8">
                        <div className="alert alert-danger">
                            <p className="mb-0 font-weight-normal">
                                {props.location.state.errorMessage}
                            </p>
                        </div>
                    </div>
                }
                {(typeof props.location.state === 'object' && props.location.state.hasOwnProperty("successMessage")) &&
                    <div className="col-lg-8">
                        <div className="alert alert-success">
                            <p className="mb-0 font-weight-normal">
                                {props.location.state.successMessage}
                            </p>
                        </div>
                    </div>
                }
                <div className="col-md-6">
                    <div className="authincation-content">
                        <div className="row no-gutters">
                            <div className="col-xl-12">
                                <div className="auth-form">
                                    <h4 className="text-center mb-4" id="logocabecera">
                                        <Logo />
                                    </h4>
                                    {(errorMessage !== "") &&
                                        <div className="alert alert-danger">
                                            <p className="mb-0 font-weight-normal">
                                                {errorMessage}
                                            </p>
                                        </div>
                                    }
                                    <form className="form-valide-with-icon" onSubmit={(e) => goLogin(e)} action="" method="post">
                                        <div className="form-group">
                                            <label htmlFor="username" className="mb-1"><strong>Usuario</strong></label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"> <i className="fa fa-user"></i> </span>
                                                </div>
                                                <input 
                                                    value={username}
                                                    onChange={(e) => setusername(e.target.value)}
                                                    type="text" 
                                                    className={(errors && errors !== null && errors.hasOwnProperty('username') ? 'is-invalid' : '') + ' form-control'}
                                                    id="username"
                                                    name="username" 
                                                    placeholder="Ingresar usuario" 
                                                />
                                            </div>
                                            {(errors && errors !== null && errors.hasOwnProperty('username')) &&
                                                <div>
                                                    <p className="mb-0 small text-danger">{errors.username}</p>
                                                </div>
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password" className="mb-1"><strong>Clave</strong></label>
                                            <div className="input-group transparent-append">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"> <i className="fa fa-lock"></i> </span>
                                                </div>
                                                <input 
                                                    value={password}
                                                    onChange={(e) => setpassword(e.target.value)}
                                                    type={(showPsw ? 'text' : 'password')}
                                                    className={(errors && errors !== null && errors.hasOwnProperty('password') ? 'is-invalid' : '') + ' form-control'}
                                                    id="password" 
                                                    name="password" 
                                                    placeholder="Ingresar clave" 
                                                />
                                                <div className="input-group-append show-pass">
                                                    <span className="input-group-text" onClick={() => setShowPsw(!showPsw)}> 
                                                        <i className={`fa ${(showPsw ? 'fa-eye-slash' : 'fa-eye')}`}></i>
                                                    </span>
                                                </div>
                                            </div>
                                            {(errors && errors !== null && errors.hasOwnProperty('password')) &&
                                                <div>
                                                    <p className="mb-0 small text-danger">{errors.password}</p>
                                                </div>
                                            }
                                        </div>
                                        <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                            <div className="form-group d-none">
                                               <div className="custom-control custom-checkbox ml-1">
													<input checked={rememberme === true} onChange={() => setrememberme(!rememberme)} type="checkbox" className="custom-control-input" id="basic_checkbox_1" />
													<label className="custom-control-label pl-2" htmlFor="basic_checkbox_1">Recuerdame</label>
												</div>
                                            </div>
                                            <div className="form-group">
                                                <Link to="/accounts/password-reset/">
                                                    ¿Se te olvidó tu contraseña?
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" 
                                            disabled={sending} 
                                            className="btn btn_login btn-primary btn-block">
                                                {(sending) ? <span>Cargando<i className="fa fa-spin fa-spinner ml-2"></i></span> : 'Iniciar sesión'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default withRouter(Login);
