import React, {useState, useEffect} from 'react'
import {
    Modal,
    Button,
    ProgressBar
} from 'react-bootstrap'
import axios from 'axios'
import { formatUrl } from '../utils/urls';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import AllPagesPdfViewer from '../pdf/all-pages';
import SinglePage from '../pdf/single-page';

function ModalShowFile(props) {

    const [loading, setloading]                         = useState(true);
    const [error, seterror]                             = useState(false);

    const [file,   setfile]                             = useState(null);
    const [name, setname]                               = useState((props.file) ? props.file.name : '');
    const [format, setformat]                           = useState('');

    useEffect(() => {
        if(props.file !== file && props.file !== null && props.file !== undefined){
            
            let filename = props.file.name.split('.');
            let formattext = filename[filename.length - 1].toLowerCase();
            let filenametext = props.file.name.substring(0, props.file.name.length - (formattext.length + 1));

            setfile(props.file);
            setformat(formattext);
            setname(filenametext);

            if(loading){
                requestFileUrl();
            }
        } 
    });

    const closeModal = () => {
        props.close();
        setfile(null);
        setname('');
        setformat('');
        seterror(false);
        setloading(false);
    }

    const requestFileUrl = () => {
        setloading(false);
    }

    return (
        <div>
            <Modal animation={false} show={props.show} onHide={() => closeModal()} dialogClassName={ (format === 'pdf' || format === 'ppt' || format === 'pptx') ? "modal-pdf" : ""}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <strong>
                            Visualizar {props.file ? 'archivo' : 'archivo'}{(format === 'pdf' || format === 'ppt' || format === 'pptx') &&<span>: {file.name}</span> }
                        </strong>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!loading &&
                        <div>
                            {file !== null &&
                                <div className="file-creator">
                                    <div className="row">
                                        <div className="col-12">

                                            {(format !== 'pdf' && format !== 'ppt' && format !== 'pptx') &&
                                                <h6>
                                                    Nombre del archivo: <strong>{file.name}</strong>
                                                </h6> 
                                            }

                                            <h6 className="d-none">
                                                Preview:
                                            </h6>  

                                            {(format === 'pdf' || format === 'ppt' || format === 'pptx') &&
                                                <div className="pdf-container p-2">
                                                    <AllPagesPdfViewer pdf={file.file}  />
                                                </div>
                                            }

                                            {(format === 'png' || format === 'jpg' || format === 'jpeg') &&
                                                <div className="py-3 preview text-center">
                                                    <div className="container position-relative">
                                                        <LazyLoadImage 
                                                            className="img-fluid"
                                                            alt={file.name}
                                                            effect="blur"
                                                            src={formatUrl(file.file)}  
                                                        />
                                                    </div>
                                                </div> 
                                            }

                                            {(format === 'mp4' || format === 'mkv' || format === 'm4v') &&
                                                <div className="py-3 preview text-center">
                                                    <video width="400" height="240" controls>
                                                        <source 
                                                            src={formatUrl(file.file)} 
                                                            // type={'video/'+format} 
                                                        />
                                                        Este navegador no es compatible con este video.
                                                    </video>
                                                </div> 
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={props.close}>
                        <i className="fa fa-times mr-2"></i> Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalShowFile
