//modules
import {combineReducers} from 'redux'

//reducers
import sessionReducer from './session/reducer'
import dashboardreducer from './dashboard/reducer'

const rootReducer = combineReducers({
    session: sessionReducer,
    dashboard: dashboardreducer
});

export default rootReducer;