import React, {useState, useCallback, useEffect, useRef} from 'react'
import {
    Modal,
    Button,
    ProgressBar
} from 'react-bootstrap'
import FileInput from './FileInput'
import axios from 'axios'
import {useDispatch} from 'react-redux'

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { set_photo } from '../../store/session/actions'

function ModalPhoto(props) {

    const [showModal,       setshowModal]  = useState(false);

    const imgRef = useRef(null);
    const previewCanvasRef      = useRef(null);

    const [files, setfiles]     = useState(null);
    const [url,  seturl]        = useState(null);

    const [sending, setsending] = useState(false);

    const [successmessage, setsuccessmessage]   = useState('');
    const [errormessage, seterrormessage]       = useState('');

    const [progress, setprogress]                       = useState(0);
    const [count,    setcount]                          = useState(0);

    const dispatch = useDispatch();

    //crop options
    const [crop, setCrop] = useState({ 
            unit: 'px', 
            width: 120,
            height: 120, 
            aspect: 1 / 1 
    });
    const [completedCrop, setCompletedCrop] = useState(null);

    const printFile = (selectFile) => {
        var reader  = new FileReader();
        reader.onloadend = function () {
            let result = reader.result;
            seturl(result);
        }
        setCrop({ 
            unit: 'px', 
            width: 120,
            height: 120, 
            aspect: 1 / 1 
        });

        reader.readAsDataURL(selectFile);
    }


    const changeInputFile = (inputfiles) => {
        if(inputfiles.length > 0){
            setfiles(inputfiles);
            let photoImg = inputfiles[0];
            printFile(photoImg);

            //console.log(photoImg);
        }
    }

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        console.log(props.show);

        if(props.show === true){
            if(!showModal){
                setshowModal(props.show);
                setCrop({ 
                    unit: 'px', 
                    width: 120,
                    height: 120, 
                    aspect: 1 / 1 
                });
                setCompletedCrop(null);
                setfiles(null);
                seturl(null);
                setsending(false);
                setsuccessmessage('');
                seterrormessage('');
                setprogress(null);
                setcount(null);
            }
        }else{
            setshowModal(props.show);
        }

        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
          return;
        }
    
        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;
        const scaleX = image.naturalWidth  / image.width;
        const scaleY = image.naturalHeight / image.height;


        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;
    
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
    
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'medium';
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,

          crop.width * scaleX,
          crop.height * scaleY,

          0,
          0,

          crop.width,
          crop.height
        );

      });

    function dataURLtoFile(dataurl, filename) {
 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }

    const reset = () => {
        setfiles(null);
        seturl(null);
        setsending(false);
        setTimeout(() => {
            setsuccessmessage("");
            props.close();
        }, 2000);
        //setsuccessmessage('');
        //seterrormessage('');
    };
    
    const saveImg = (e, canvas, crop) => {
        e.preventDefault();
        let newImg = null;
        let url = '/accounts/update-photo/';

        if (!crop || !canvas) {
            return;
        }
        
        let dataURL = canvas.toDataURL();
        var file = dataURLtoFile(dataURL,'photo.png');
        let formdata = new FormData();
        formdata.append('photo', file);
        
        setsending(true);
        setsuccessmessage('');
        setprogress(0.2);
        seterrormessage('');

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: function(progressEvent) {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                console.log(percentCompleted);
                setprogress( percentCompleted );
                setcount(count * percentCompleted);
            }
            /*
            onUploadProgress: (progressEvent) => {
                const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                console.log("onUploadProgress", totalLength);
                console.log(progressEvent.loaded);

                if (totalLength !== null) {
                    let total = (progressEvent.loaded * 100)/totalLength;
                    console.log(total);
                    setprogress( total );
                    setcount(count * total);
                }    
            }
            */
        }

        axios.put(
            url,
            formdata,
            config
        ).then((res) => {
            if(res.status === 200){
                console.log(res);
                setprogress(0);
                setsuccessmessage('Foto actualizada correctamente');
                setsending(false);
                dispatch(set_photo(res.data.photo));
                reset();
            }
        }).catch((err) => {
            console.error(err);
            setprogress(0);
            seterrormessage('La foto no pudo actualizarce debido a un error inesperado')
            setsending(false);
        });
        
    }

    return (
        <Modal show={showModal} onHide={props.close}>
            <Modal.Header closeButton>
            <Modal.Title>
                <strong>Editar foto de perfil</strong>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(successmessage !== '') &&
                    <div className="mb-3 alert alert-success">
                        <p className="mb-0 font-weight-bold">{successmessage}</p>
                    </div>
                }

                {(errormessage !== '') &&
                    <div className="mb-3 alert alert-danger">
                        <p className="mb-0 font-weight-bold">{errormessage}</p>
                    </div>
                }

                { (progress > 0) &&
                    <div className="py-3">
                        <ProgressBar variant="success" animated now={progress} />
                    </div>
                }
                <div className="photo-editor">
                    <div className="row">
                        <div className="col-12">
                            <FileInput preview={false} value={files} onChange={(e) => changeInputFile(e)} />
                            {(files !== null && files.length > 0) && 
                                <div>
                                    {(url !== null) &&
                                        <div>
                                            <div className="row">
                                                <div className="d-none col-3">
                                                    <h6 className="py-3 font-weight-bold">
                                                        Foto:
                                                    </h6>
                                                    <img 
                                                        src={url} 
                                                        alt="img preview" 
                                                        className="img-fluid" 
                                                    />
                                                </div>
                                                <div className="col-5">
                                                    <h6 className="py-3 font-weight-bold">
                                                        Recortar foto:
                                                    </h6>
                                                    <ReactCrop
                                                        src={url}
                                                        onImageLoaded={onLoad}
                                                        crop={crop}
                                                        onChange={(c) => setCrop(c)}
                                                        onComplete={(c) => setCompletedCrop(c)}
                                                    />
                                                </div>
                                                {(completedCrop || previewCanvasRef.current || imgRef.current) &&
                                                    <div className="col-7">
                                                        <h6 className="py-3 font-weight-bold">
                                                            Resultado:
                                                        </h6>
                                                        <canvas
                                                            ref={previewCanvasRef}
                                                            // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                                                            style={{
                                                                width: Math.round(completedCrop?.width ?? 0),
                                                                height: Math.round(completedCrop?.height ?? 0)
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={sending} onClick={(e) => saveImg(e, previewCanvasRef.current, completedCrop)} variant="primary" type="button" >
                    {(sending) ? <span><i className="fa fa-spinner fa-spin mr-2"></i>Cargando</span> : <span><i className="fa fa-ckeck mr-2"></i>Guardar foto</span>}
                </Button>
                <Button variant="light" onClick={props.close}>
                    <i className="fa fa-times mr-2"></i>Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalPhoto
