import React, {useState} from 'react'
import axios from "axios"
import {useSelector} from 'react-redux'
import {OverlayTrigger, Tooltip, Dropdown} from "react-bootstrap"

function BtnAddToFavourities(props) {  

    const session = useSelector(state => state.session);
    const userPermissions = session.userData !== null ? session.userData.permissions : null;

    let url = "/filemanager/favorites/"+props.itemId+"/";

    const [loading, setloading] = useState(false);
    const [message, setmessage] = useState('');

    const [status,  setstatus]  = useState(props.status);

    //let status = props.status ? props.status : false;
    //console.log(props.status ,status, props.itemId);

    const addToFavourities = () => {
        setloading(true);
        axios.post(url).then((res) => {
            //console.log(res.data);
            setloading(false);
            if(props.updateOnChage){
                props.updateOnChage(props.itemId);
            }
            setstatus(true);
            //setstatus(true);
            /*
            setmessage("Agregado");
            setTimeout(() => {
                setmessage("");
            }, 800);
            */
        });
    }

    const removeFavorite = () => {
        setloading(true);
        console.log(props.itemId);

        axios.delete(url).then((res) => {
            //console.log(res.status);
            setloading(false);
            if(props.updateOnChage){
                props.updateOnChage(props.itemId);
            }
            setstatus(false);
            /*
            if(props.updateOnChage){
                //setstatus(false);
                props.updateOnChage(props.itemId);  
            }else{
                //setstatus(false);
                setmessage("Removido");
                setTimeout(() => {
                    setmessage("");
                }, 800);
            }
            */
        });
    }

    if(userPermissions !== null && userPermissions.can_create_and_delete_favorites){
        if(props.type && props.type === "dropdown"){
            return(
                <Dropdown.Item onClick={() => (status ? removeFavorite() : addToFavourities())} >
                    <span className={status ? "text-warning" : ""}>
                        {loading 
                        ?
                            <i className="fa fa-spin fa-spinner mr-2" />
                        :
                            <i className={`${status ? "fa" : "far"} fa-star mr-2`}/>
                        }
                        <span>
                            {status ? "Quitar de favoritos" : "Agregar a favoritos"}
                        </span>
                    </span>
                </Dropdown.Item>
            )
        }else{
            return (
                <div className="d-inline-block">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-btn-favourite-${props.itemId}`}>
                                Favorito
                            </Tooltip>
                        }
                    >
                        <button 
                            disabled={loading}
                            onClick={() => (status ? removeFavorite() : addToFavourities())} 
                            className={`btn ${status ? "bg-secondary text-dark" : "border-secondary btn_off"} btn-sm py-2 ml-2 px-4 h-100`}
                        >
                            {loading 
                            ? 
                                <i className={`${status ? "fa" : "fa-spin fa-spinner"}`}/>
                            : 
                                <i className={`${status ? "fa" : "far"} fa-star`}/>
                            }
                                <span className="d-none">Favorito</span>
                        </button>
                    </OverlayTrigger>

                    {(message !== "") &&
                        <p className="pt-2 mb-0 text-center" style={{fontSize: "10px"}}>
                            {message}
                        </p>
                    }
                </div>
            )
        }
    }else{
        return ""
    }
}

export default BtnAddToFavourities
