import React, {useState, Fragment} from 'react'
import { FileIcon, defaultStyles } from 'react-file-icon';
import {Link} from "react-router-dom"
import axios from 'axios'
import { formatUrl } from '../utils/urls';
import InlineLoader from './InlineLoader';
import { OverlayTrigger, Tooltip, Popover, PopoverContent } from 'react-bootstrap';
import folderImg from '../../static/images/folder.png';

function CardSearchTop() {

    const [textsearch,  settextsearch]  = useState('');
    const [searching,   setsearching]   = useState(false);
    const [data,        setdata]        = useState(null);
    const [errors,      seterrors]      = useState({});

    const [ishover, setishover]         = useState(false);

    const search = (e) => {
        e.preventDefault();
        e.stopPropagation();

        seterrors({});

        let transformText = encodeURI(textsearch.trim().replace(/\s+/gi,' '));
        let textValues    = transformText.split('%20');
        let textResult    = textValues.join('+');

        let urlprivate = "/filemanager/search-private-files/?q="+textResult;
        //let urlpublic = "/filemanager/search-public-files/?q="+textResult;

        setsearching(true);

        axios.get(urlprivate).then((res) => {
            console.log(res.data);
            setdata(res.data);
            setsearching(false);
        }).catch((err) => {
            console.error(err);
            setsearching(false);

            let response = err.response;

            if(typeof response === "object" && response.hasOwnProperty("data")){
                seterrors(response.data);
            }
        });
    }
    
    const reset = () => {
        settextsearch('');
        setsearching(false);
        setdata(null);
    }

    const changetext = (value) => {
        if(value === ''){
            reset();
            seterrors({});
        }else{
            settextsearch(value);
            seterrors({});
        }
    }

    /*
        <Tooltip style={{minWidth: "200px"}} id={`tooltip-search-top-helper`}>
            <div>
                <p className="small mb-1">
                    Para buscar archivos, digite un texto y a continuación haga clic en el ícono de la lupa o presione la tecla Enter. La búsqueda se realiza sobre los nombres de archivo. Solo se consideran los archivos privados (los archivos que se encuentran en la unidad del usuario).
                </p>   
                <p className="small">
                    Por ejemplo: Si buscara el texto "ami", se listarán todos los archivos privados (de la unidad del usuario), cuyos nombres contienen el texto "ami", como: camión, amigo, camino, etc.
                </p>      
            </div>      
        </Tooltip>
    */

    const isBlur = () => {
        setishover(false);
        setTimeout(() => {
            //setdata(null);
        }, 200);
    }

    const isFocus = () => {
        setishover(true);
    }

    return (
        <div> 

                        <form onSubmit={(e) => search(e)} action="">
                            <div className="d-flex align-items-center">
                            <div className={`search_bar dropdown show ${ishover ? "is-hover" : ""}`}>
                                <div className="dropdown-menu p-0 m-0 show">
                                    <input 
                                        value={textsearch} 
                                        onChange={(e) => changetext(e.target.value)} 
                                        className="form-control" 
                                        type="search" 
                                        placeholder="Buscar en mi unidad..." 
                                        aria-label="Search" 
                                        onFocus={() => isFocus()}
                                        onBlur={() => isBlur()}
                                    />
                                </div>
                                <button 
                                    disabled={searching} 
                                    type="submit"
                                    className="search_icon p-3 c-pointer" 
                                    data-toggle="dropdown"
                                >
                                    {(searching) 
                                    ?
                                        <i className="fa fa-spin fa-spinner small text-muted"></i>
                                    :
                                        <Fragment>
                                            <svg width={20} height={20} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z" fill="#A4A4A4" /></svg>
                                        </Fragment>
                                    }
                                </button>
                            </div>
                            <div className="d-lg-block d-none">
                                <OverlayTrigger
                                    placement="bottom"
                                    //trigger="click"
                                    overlay={
                                        <Popover className="popover-primary" id="popover-basic">
                                            <Popover.Title as="h3">
                                                Ayuda
                                            </Popover.Title>
                                            <Popover.Content>
                                                <div>
                                                    <p>
                                                        Para buscar archivos, digite un texto y a continuación haga clic en el ícono de la lupa o presione la tecla Enter. La búsqueda se realiza sobre los nombres de archivo. Solo se consideran los archivos privados (los archivos que se encuentran en la unidad del usuario).
                                                    </p>   
                                                    <p>
                                                        <strong>Por ejemplo:</strong> Si buscara el texto "ami", se listarán todos los archivos privados (de la unidad del usuario), cuyos nombres contienen el texto "ami", como: camión, amigo, camino, etc.
                                                    </p>
                                                </div> 
                                            </Popover.Content>
                                        </Popover>
                                    }
                                >
                                    <i style={{fontSize: "22px"}} className="fa fa-question text-primary ml-3"></i>
                                </OverlayTrigger>
                            </div>
                            </div>

                            {((data !== null && Array.isArray(data)) || searching || errors.hasOwnProperty("detail")) &&
                                <div className="search-results-container results-top">
                                    {!searching &&
                                        <div className="card p-4">
                                            {(data !== null && data.length > 0) &&
                                                <ul className="search-results-container-data">
                                                    {data.map((item, key) => {
                                                        let filename = item.name.split('.');
                                                        let formattext = filename[filename.length - 1].toLowerCase();
                                                        
                                                        let type = item.type;

                                                        if(type === "F"){
                                                            return (
                                                                <li className="d-flex link-item-preview-list w-100 align-items-center" key={key}>
                                                                    <Link 
                                                                        onClick={() => reset()}
                                                                        className="d-flex w-100 py-2 align-items-center" 
                                                                        to={"/file/preview/"+item.id+"/"}
                                                                    >

                                                                        <div style={{ width: "25px", marginRight: "15px" }}>
                                                                            <div style={{ width: "25px"}}></div>
                                                                            <FileIcon radius={12} extension={formattext} {...defaultStyles[formattext]} />
                                                                        </div>
                                                                        <span className="mb-0 small d-inline-flex text-dark">
                                                                            {item.name}
                                                                        </span>

                                                                    </Link>
                                                                </li>
                                                            )
                                                        }else{
                                                            let folderUrl = "";

                                                            if(item.folder_subtype === "D"){
                                                                folderUrl = "/unidad/folder/";
                                                            }else if(item.folder_subtype === "SR"){
                                                                folderUrl = "/compartido-conmigo/folder/";
                                                            }else if(item.folder_subtype === "SD"){
                                                                folderUrl = `/compartido-conmigo/folder/${item.root_shared_folder}/descendant/`;
                                                            }

                                                            return (
                                                                <li className="d-flex link-item-preview-list w-100 align-items-center" key={key}>
                                                                    <Link 
                                                                        onClick={() => reset()}
                                                                        className="d-flex w-100 py-2 align-items-center" 
                                                                        to={folderUrl+item.id+"/"}
                                                                    >
                                                                        <div style={{ width: "30px", marginRight: "10px" }}>
                                                                            <img 
                                                                                className="img-fluid rounded mr-3 d-xl-inline-block" 
                                                                                width={70} 
                                                                                src={folderImg} 
                                                                                alt="Carpeta" 
                                                                            />
                                                                        </div>
                                                                        <span className="mb-0 small d-inline-flex text-dark">
                                                                            {item.name}
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                            )
                                                        }
                                                        

                                                    })}
                                                </ul>
                                            }

                                            {(errors.hasOwnProperty("detail")) &&
                                                <div className="alert alert-danger small mb-0 font-weight-bold">
                                                    <i className="fa fa-exclamation mr-2"></i>{errors.detail}
                                                </div>
                                            }

                                            {(data !== null && data.length === 0 && !errors.hasOwnProperty("detail")) &&
                                                <p className="text-center mb-0">
                                                    <i className="fa fa-exclamation-triangle mr-2"></i>
                                                    Sin resultados.
                                                </p>
                                            }
                                        </div>
                                    }
                                    

                                    {searching &&
                                        <div className="card p-4">  
                                            <InlineLoader />
                                            <p className="mb-0 d-none text-center">
                                                    <i className="fa fa-spin fa-spinner fa-1x text-muted"></i>
                                            </p>
                                        </div>    
                                    }
                                </div>
                            }
                        </form>

        </div>
    )
}

export default CardSearchTop;
