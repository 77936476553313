import React, {useState} from 'react'
import axios from 'axios'
import {useSelector, useDispatch} from 'react-redux'
import { set_user_data } from '../../store/session/actions';

function ChangePassword(props) {
    let data = props.data;
    const dispatch = useDispatch();

    const [sending, setsending]                 = useState(false);
    const [globalerror,  setglobalerror]        = useState(null);
    const [errors, seterrors]                   = useState(null);
    const [successmessage, setsuccessmessage]   = useState('');

    const [password, setpassword] = useState('');
    const [oldpassword, setoldpassword] = useState('');
    const [confirmpassword, setconfirmpassword] = useState('')


    let urlChangePasword = '/accounts/change-password/';

    const validate = () => {
        let counterrors = 0;
        let errorslist = {};

        /*
        if(oldpassword === ''){
            errorslist.oldpassword = 'Ingrese su contraseña';
            counterrors++;
        }else if(oldpassword.length > 30){
            errorslist.oldpassword = '¡Contraseña demasiado larga!';
            counterrors++;
        }

        if(password === ''){
            errorslist.password = 'Ingrese una nueva contraseña';
            counterrors++;
        }else if(password.length > 30){
            errorslist.password = 'Nueva contraseña es demasiado larga';
            counterrors++;
        } 
        

        if(confirmpassword === ''){
            errorslist.confirmpassword = '¡Confirme su contraseña!';
            counterrors++;
        }else if(confirmpassword.length > 30){
            errorslist.confirmpassword = '¡Contraseña demasiado larga!';
            counterrors++;
        }else{
            if(password !== confirmpassword){
                errorslist.confirmpassword  = 'Las contraseñas deben coincidir';
                errorslist.password         = 'Las contraseñas deben coincidir';
                counterrors++;
            }
        }

        if(counterrors > 0){
            seterrors(errorslist);
            return false;
        }
        */

        return true;
    }

    const GoChangePassword = (e) => {
        e.preventDefault();
        e.stopPropagation();
        seterrors(null);
        setsuccessmessage('');

        seterrors(null);
        let isValid = validate();

        if(isValid){

            setsending(true);

            axios({
                method: 'PUT',
                url: urlChangePasword,
                data: {
                    old_password: oldpassword,
                    new_password1: password,
                    new_password2: confirmpassword,
                }
            }).then((res) => {
                console.log(res.data);
                setsuccessmessage('Contraseña actualizada');
                setsending(false);
            }).catch((err) => {
                setsending(false);

                if(err.response){
                    seterrors(err.response.data);
                }
            })
        }
    }

    return (
        <div className="settings-form">
            {(successmessage !== '') &&
                <div className="alert alert-success">
                    <p className="mb-0 font-weight-normal">{successmessage}</p>
                </div>
            }
            {(errors && errors !== null && errors.hasOwnProperty('non_field_errors')) &&
                <div className="alert alert-danger">
                    <p className="mb-0 small text-danger">{errors.non_field_errors[0]}</p>
                </div>
            }
            <h4 className="text-primary">Cambio de contraseña</h4>
            <p>
                Ingrese su contraseña anterior, por razones de seguridad, y luego ingrese su nueva contraseña dos veces para que podamos verificar que la ingresó correctamente.
            </p>
            <form onSubmit={(e) => GoChangePassword(e)} >
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label htmlFor="oldpassword">Contraseña antigua</label>
                        <input id="oldpassword" type="password" 
                        value={oldpassword} onChange={(e) => setoldpassword(e.target.value)} 
                        placeholder="Contraseña antigua" 
                        className={(errors && errors !== null && errors.hasOwnProperty('old_password') ? 'is-invalid' : '') + ' form-control'} 
                        />
                        {(errors && errors !== null && errors.hasOwnProperty('old_password')) &&
                            <div>
                                <p className="mb-0 small text-danger">{errors.old_password[0]}</p>
                            </div>
                        }
                    </div>
                    <div className="form-group col-md-12">
                        <label htmlFor="password">Contraseña nueva</label>
                        <input id="password" type="password" 
                        value={password} onChange={(e) => setpassword(e.target.value)} 
                        placeholder="Contraseña nueva" 
                        className={(errors && errors !== null && errors.hasOwnProperty('new_password1') ? 'is-invalid' : '') + ' form-control'} 
                        />
                        {(errors && errors !== null && errors.hasOwnProperty('new_password1')) &&
                            <div>
                                <p className="mb-0 small text-danger">{errors.new_password1[0]}</p>
                            </div>
                        }
                        <ul className="my-3 small pl-3">
                            <li>
                                <i className="fa fa-circle text-primary mr-3"></i>Su contraseña no puede asemejarse tanto a su otra información personal.
                            </li>
                            <li>
                                <i className="fa fa-circle text-primary mr-3"></i>Su contraseña debe contener al menos 8 caracteres.
                            </li>
                            <li>
                                <i className="fa fa-circle text-primary mr-3"></i>Su contraseña no puede ser una clave utilizada comúnmente.
                            </li>
                            <li>
                                <i className="fa fa-circle text-primary mr-3"></i>Su contraseña no puede ser completamente numérica.
                            </li>
                        </ul>
                    </div>
                    <div className="form-group col-md-12">
                        <label htmlFor="confirmpassword">Contraseña nueva (confirmación)</label>
                        <input id="confirmpassword" type="password" 
                        value={confirmpassword} onChange={(e) => setconfirmpassword(e.target.value)} 
                        placeholder="***" 
                        className={(errors && errors !== null && errors.hasOwnProperty('new_password2') ? 'is-invalid' : '') + ' form-control'} 
                        />
                        {(errors && errors !== null && errors.hasOwnProperty('new_password2')) &&
                            <div>
                                <p className="mb-0 small text-danger">{errors.new_password2[0]}</p>
                            </div>
                        }
                    </div>
                </div>
                <button disabled={sending} type="submit" className="btn btn-primary">
                    {(sending) ? <span><i className="fa fa-spinner fa-spin mr-2"></i>Cargando</span> : <span><i className="fa fa-pencil mr-2"></i>Cambiar mi contraseña</span>}
                </button>
            </form>
        </div>
    )
}

export default ChangePassword
