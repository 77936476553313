//modules
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

//store
import store from './store/store';

//components
import App from './App';
import AppRouter from './components/page/AppRouter';

//styles
import './static/fonts/fonts.css'
import './static/css/style.css';
import './static/css/repositorio.css';

import './static/scss/app.scss';

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <App>
          <AppRouter />
        </App>
      </Provider>
  </React.StrictMode>,

  document.getElementById('app')
);

