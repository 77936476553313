import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom'
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

function App(props) {
  return (
    <Router>
      <div className="App">
        {props.children}

        <NotificationContainer/>
      </div>
    </Router>
  );
}

export default App;
