import React from 'react'
import {useSelector} from "react-redux"

function Footer() {

    const year = useSelector(state => state.session.settings.filemanager.current_year);
    console.log(year);

    return (
        <footer>
            <div className="footer">
                <div className="copyright">
                    <p className="mb-0">{year} © Mobil</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
