import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { SizeMe } from 'react-sizeme'

export default function AllPagesPdfViewer(props) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  
  const { pdf } = props;
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  let file = pdf;

  let fileurl = file.split('.');
  let formattext = fileurl[fileurl.length - 1].toLowerCase();

  if(formattext === "ppt" || formattext === "pptx"){
    file = file.substring(0, file.length - formattext.length);
    file += "pdf"
  }

  console.log(file);

  return (
      <SizeMe>
          {({ size }) => (
            <Document
              file={file}
              loading={<p className="mb-0">Cargando vista previa...</p>}
              error={<p className="mb-0">El archivo no se puede previsualizar</p>}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={console.error}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page 
                  key={`page_${index + 1}`} 
                  pageNumber={index + 1} 
                  width={size.width ? size.width : 1} 
                />
              ))}
            </Document>
          )}
      </SizeMe>
  );
}