import React, {useState} from 'react'
import axios from "axios"
import {useSelector} from 'react-redux'
import {OverlayTrigger, Tooltip, Dropdown} from "react-bootstrap"
import {NotificationContainer, NotificationManager} from 'react-notifications';

function BtnAddToSymbolicLink(props) {  

    const session = useSelector(state => state.session);
    const userPermissions = session.userData !== null ? session.userData.permissions : null;

    let url = "/filemanager/symbolic-link/"+props.itemId+"/";

    const [loading, setloading] = useState(false);
    const [status,  setstatus]  = useState(props.status);
    const [message, setmessage] = useState('');
    const [errmessage, seterrmessage] = useState('');

    const addToFavourities = () => {
        setloading(true);
        axios.post(url).then((res) => {

            console.log(res.data);
            setloading(false);
            props.updateOnChage(props.itemId);
            setstatus(true);

            /*
                setstatus(true);
                setmessage("Acceso creado");
                setTimeout(() => {
                    setmessage("");
                }, 800);
            */
        }).catch((err) => {
            setloading(false);
            
            let errres = err.response;
            if(typeof errres === "object"){
                errres = err.response.data;
                if(typeof errres === "object" && errres.hasOwnProperty("non_field_errors")){
                    seterrmessage(errres.non_field_errors);
                    NotificationManager.error(errres.non_field_errors, "Ha ocurrido un problema");

                    setTimeout(() => {
                        seterrmessage("");
                    }, 2000);
                }
            }
        });
    }

    const removeFavorite = () => {
        setloading(true);
        axios.delete(url).then((res) => {

            console.log(res.status);
            setloading(false);
            props.updateOnChage(props.itemId);
            setstatus(false);

            /*
            if(props.updateOnChage){
                setstatus(false);
                setTimeout(() => {
                    props.updateOnChage(props.itemId);
                }, 100);
            }else{
                setstatus(false);
                setmessage("Acceso removido");
                setTimeout(() => {
                    setmessage("");
                }, 800);
            }
            */
        });
    }

    if(userPermissions !== null && userPermissions.can_create_and_delete_symbolic_links){
        if(props.type && props.type === "dropdown"){
            return(
                <Dropdown.Item onClick={() => (status ? removeFavorite() : addToFavourities())}>
                    <span className={status ? "text-info" : ""}>
                        {loading 
                        ?
                            <i className="fa fa-spin fa-spinner mr-2" />
                        :
                            <i className={`${status ? "fa" : "fa"} fa-share-square mr-2`}/>
                        }
                        <span>{status ? "Retirar Acceso Directo" : "Crear Acceso Directo"}</span>
                    </span>
                </Dropdown.Item>
            )
        }else{
            return (
                <div className="d-inline-block">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-btn-symbolic-${props.itemId}`}>
                                Acceso Directo
                            </Tooltip>
                        }
                    >
                        <button 
                            disabled={loading}
                            onClick={() => (status ? removeFavorite() : addToFavourities())} 
                            className={`btn ${status ? "bg-info text-white" : "border-info btn_off"} btn-sm py-2 ml-2 px-4 h-100`}
                        >
                            {loading 
                            ? 
                                <i className={`${status ? "fa" : "fa"} fa-share-square`}/>
                            : 
                                <i className={`${status ? "fa" : "fa"} fa-share-square`}/>
                            }
                                <span className="d-none">Acceso Directo</span>
                        </button>
                    </OverlayTrigger>

                    {(message !== "") &&
                        <p className="pt-2 mb-0 text-center" style={{fontSize: "10px"}}>
                            {message}
                        </p>
                    }

                    {(errmessage !== "") &&
                        <p className="pt-2 mb-0 text-center text-danger" style={{fontSize: "10px"}}>
                            {errmessage}
                        </p>
                    }
                </div>
            )
        }
    }else{
        return ""
    }
}

export default BtnAddToSymbolicLink
