import React, {useState, useEffect, Fragment, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import axios from 'axios'
import {withRouter, Link} from 'react-router-dom'
import { FileIcon, defaultStyles } from 'react-file-icon';
import {OverlayTrigger, Tooltip} from "react-bootstrap"

import dummyImg from '../../../../static/images/avatar/1.jpg';
import folderImg from '../../../../static/images/folder.png';
import ModalFolder from '../../../helpers/ModalFolder';
import ModalDeleteFolder from '../../../helpers/DeleteFolder';
import UploadFile from '../../../helpers/UploadFile';
import ModalRenameFolder from '../../../helpers/RenameFolder';
import ModalDeleteFile from '../../../helpers/DeleteFile';
import ModalRenameFile from '../../../helpers/RenameFile';
import { downloadURI, formatUrl, downloadFileFromUrl, downloadFunction } from '../../../utils/urls';
import ModalShowFile from '../../../helpers/ShowFile';
import Loader from '../../../helpers/Loader';
import BtnAddToFavourities from '../../../helpers/BtnAddToFavourities';
import DownloadLink from "react-download-link";
import ModalShareFile from '../../../helpers/ModalShareFile';
import ModalShareFolder from '../../../helpers/ModalShareFolder';
import NameComponent from '../../../helpers/Files/NameComponent';
import ModalUpdateStatus from '../../../helpers/Files/ModalUpdateStatus';
import DataGroupButtons from '../../../helpers/DataGroupButtons';

function FolderCompartido(props) {

    const [loading, setloading] = useState(true);
    const [search,  setsearch]  = useState(true);
    const [data, setdata]       = useState(null);

    const [errormessage, seterrormessage] = useState("");
    const [successmessage, setsuccessmessage] = useState("");

    const [list, setlist]       = useState(null);
    
    let idInPath    = (props.match.params.id) ? props.match.params.id : null;
    let idSubfolder = (props.match.params.subfolder) ? props.match.params.subfolder : null;

    const [path, setpath]                 = useState(idInPath);
    const [subfolder, setsubfolder]       = useState(idSubfolder);

    const [folderSelected, setfolderSelected]       = useState(null);
    const [fileSelected,   setfileSelected]       = useState(null);

    const session = useSelector(state => state.session);
    const userPermissions = session.userData.permissions;

    const dispatch        = useDispatch();
    const downloadList    = useSelector(state => state.dashboard.download_list);
    console.log("Lista de descargas:",downloadList);

    //modals
    const [showModalFolder, setShowModalFolder] = useState(false);
    const handleCloseModalFolder = () => {
      setShowModalFolder(false);
    }
    const handleShowModalFolder = () => {
      setShowModalFolder(true)
    };

    //FILES___________________________________________________________________________
    //rename
    const [showModalRenameFile, setShowModalRenameFile] = useState(false);
    const handleCloseModalRenameFile = () => {
      setShowModalRenameFile(false);
    }
    const handleShowModalRenameFile = () => {
      setShowModalRenameFile(true)
    };

    //delete
    const [showModalDeleteFile, setShowModalDeleteFile] = useState(false);
    const handleCloseModalDeleteFile = () => {
      setShowModalDeleteFile(false);
    }
    const handleShowModalDeleteFile = () => {
      setShowModalDeleteFile(true)
    };

    //share
    const [showModalShareFile, setShowModalShareFile] = useState(false);
    const handleCloseModalShareFile = () => {
      setShowModalShareFile(false);
    }
    const handleShowModalShareFile = () => {
      setShowModalShareFile(true)
    };

    //show
    const [showModalShowFile, setShowModalShowFile] = useState(false);
    const handleCloseModalShowFile = () => {
      setShowModalShowFile(false);
    }
    const handleShowModalShowFile = () => {
      setShowModalShowFile(true)
    };
    
    //FOLDER___________________________________________________________________________
    //delete
    const [showModalDeleteFolder, setShowModalDeleteFolder] = useState(false);
    const handleCloseModalDeleteFolder = () => {
      setShowModalDeleteFolder(false);
    }
    const handleShowModalDeleteFolder = () => {
      setShowModalDeleteFolder(true)
    };
    

    //rename
    const [showModalRenameFolder, setShowModalRenameFolder] = useState(false);
    const handleCloseModalRenameFolder = () => {
      setShowModalRenameFolder(false);
    }
    const handleShowModalRenameFolder = () => {
      setShowModalRenameFolder(true)
    };

    //share
    const [showModalShareFolder, setShowModalShareFolder] = useState(false);
    const handleCloseModalShareFolder = () => {
      setShowModalShareFolder(false);
    }
    const handleShowModalShareFolder = () => {
      setShowModalShareFolder(true)
    };

    const shareFolderNow = (folder) => {
      setfolderSelected(folder);
      handleShowModalShareFolder();
    }

    const shareFileNow = (file) => {
      setfileSelected(file);
      handleShowModalShareFile();
    }

    const deleteFolderNow = (folder) => {
      setfolderSelected(folder);
      handleShowModalDeleteFolder();
    }

    const RenameFolderNow = (folder) => {
      setfolderSelected(folder);
      handleShowModalRenameFolder();
    }

    const deleteFileNow = (file) => {
      setfileSelected(file);
      handleShowModalDeleteFile();
    }

    const RenameFileNow = (file) => {
      setfileSelected(file);
      handleShowModalRenameFile();
    }

    const showFileNow = (file) => {
      setfileSelected(file);
      handleShowModalShowFile();
    }

    const refresh = () => {
        getdata();
    }

    const [showModalCreate, setShowModalCreate] = useState(false);
    const handleCloseModalCreate = () => setShowModalCreate(false);
    const handleshowModalCreate = () => setShowModalCreate(true);

    const [showModalStatus, setShowModalStatus] = useState(false);

    let subfolderId = null;

    const showModal = (item, typeModal, typeItem) => {

      if(typeItem === "folder"){
        setfolderSelected(item);

        switch (typeModal) {
          case "rename":
            setShowModalRenameFolder(true);
            break;

          case "move":
            //setShowModalMoveFile(true);
            break;

          case "share":
            setShowModalShareFolder(true);
            break;

          case "delete":
            setShowModalDeleteFolder(true);
            break;
        
          default:
            break;
        }
      }else if(typeItem === "file"){
        setfileSelected(item);

        switch (typeModal) {
          case "rename":
            setShowModalRenameFile(true);
            break;

          case "move":
            //setShowModalMoveFile(true);
            break;

          case "share":
            setShowModalShareFile(true);
            break;

          case "delete":
            setShowModalDeleteFile(true);
            break;

          case "download":
            downloadFileFromUrl(null, formatUrl(item.file), item.name, downloadFunction, item, dispatch, downloadList)
            break;

          case "show":
            setShowModalShowFile(true);
            break;

          case "editStatus":
            setShowModalStatus(true);
          break;

          case "editCategory":
            //setShowModalEditCategory(true);
          break;
        
          default:
            break;
        }
      }
    }
  
    /*
    const getDataFolder = (id) => {
      if(id !== null){
        props.history.push("/unidad/folder/"+id);
      }else{
        props.history.push("/unidad");
      }
    }
    */

    const getdata = () => {
      setsearch(false);
      if(idInPath !== null){

        let url = '/filemanager/shared-with-me/folders/'+idInPath+'/';
        setpath(idInPath);

        if(idSubfolder !== null){
          url = '/filemanager/shared-with-me/folders/'+idInPath+'/descendant/'+idSubfolder+'/';
        }

        axios.get(url).then((res) => {

          console.log(res.data);
          setdata(res.data);
          setlist(res.data);
          setloading(false);

        }).catch((err) => {
          console.error(err);
        });
        
      }else{
        let url = '/filemanager/shared-with-me/';
        axios.get(url).then((res) => {
          setdata(res.data);
          let newData     = {};
          let getfiles    = res.data.filter(item => item.hasOwnProperty("file") && item.file !== null);
          let getfolders  = res.data.filter(item => item.hasOwnProperty("folder") && item.folder !== null);

          newData.files   = getfiles;
          newData.folders = getfolders;

          console.log(res.data);
          console.log(newData);
          setlist(newData);

          setloading(false);
          setpath(null);
          
        }).catch((err) => {
          console.error(err);
          if(err.hasOwnProperty("response")){
            if(err.response.data){
              console.log(err.response.data);
              if(err.response.data.hasOwnProperty("detail")){
                seterrormessage(err.response.data.detail);
              }
            }
          }
          setloading(false);
          setpath(null);
        });
      }
    }

    useEffect(() => {
      if(loading){
        if(search){
          getdata();
        }
      }else{
        if(idInPath !== path){
          setpath(idInPath);
          setloading(true);
          getdata();
        }else if(idSubfolder !== subfolder){
          setsubfolder(idSubfolder);
          setloading(true);
          getdata();
        }
      }
    });

    let itbreadcrumb = 1;

    return (
        <div className="">
          {(!loading && list !== null) 
          ?
          <div className="page-titles">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/compartido-conmigo/"}>
                  Compartido conmigo
                </Link>
              </li>
              {
                data.breadcrumbs && data.breadcrumbs.map((item, key) => {
                    
                    let url = `/compartido-conmigo/folder/`;
                    if(itbreadcrumb>1){
                      url = `/compartido-conmigo/folder/${idInPath}/descendant/`;
                    }
                    itbreadcrumb++;

                    return (
                    <li key={key} className="breadcrumb-item">
                        <Link to={url+item.id}>
                          {item.name}
                        </Link>
                    </li>
                    )
                })
              }
            </ol>
          </div>
          :
          <div className="page-titles">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/compartido-conmigo/"}>
                  Compartido conmigo
                </Link>
              </li>
            </ol>
          </div>
        }

        <div className="row mb-5 align-items-center">
          <div className="col-lg-12">
            <div className="card m-0 ">
              <div className="card-body py-3 py-md-2">
                <div className="row align-items-center">
                  <div className="col-md-5 mb-3 mb-md-0">
                    <div className="media align-items-end">
                      <span className="mr-2 mb-1">
                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0)">
                            <path d="M21 24H3C2.73478 24 2.48043 23.8946 2.29289 23.7071C2.10536 23.5196 2 23.2652 2 23V22.008C2.00287 20.4622 2.52021 18.9613 3.47044 17.742C4.42066 16.5227 5.74971 15.6544 7.248 15.274C7.46045 15.2219 7.64959 15.1008 7.78571 14.9296C7.92182 14.7583 7.9972 14.5467 8 14.328V13.322L6.883 12.206C6.6032 11.9313 6.38099 11.6036 6.22937 11.2419C6.07776 10.8803 5.99978 10.4921 6 10.1V5.96201C6.01833 4.41693 6.62821 2.93765 7.70414 1.82861C8.78007 0.719572 10.2402 0.0651427 11.784 5.16174e-06C12.5992 -0.00104609 13.4067 0.158488 14.1603 0.469498C14.9139 0.780509 15.5989 1.2369 16.1761 1.81263C16.7533 2.38835 17.2114 3.07213 17.5244 3.82491C17.8373 4.5777 17.999 5.38476 18 6.20001V10.1C17.9997 10.4949 17.9204 10.8857 17.7666 11.2495C17.6129 11.6132 17.388 11.9426 17.105 12.218L16 13.322V14.328C16.0029 14.5469 16.0784 14.7586 16.2147 14.9298C16.351 15.1011 16.5404 15.2221 16.753 15.274C18.251 15.6548 19.5797 16.5232 20.5298 17.7424C21.4798 18.9617 21.997 20.4624 22 22.008V23C22 23.2652 21.8946 23.5196 21.7071 23.7071C21.5196 23.8946 21.2652 24 21 24ZM4 22H20C19.9954 20.8996 19.6249 19.8319 18.9469 18.9651C18.2689 18.0983 17.3219 17.4816 16.255 17.212C15.6125 17.0494 15.0423 16.6779 14.6341 16.1558C14.2259 15.6337 14.0028 14.9907 14 14.328V12.908C14.0001 12.6428 14.1055 12.3885 14.293 12.201L15.703 10.792C15.7965 10.7026 15.8711 10.5952 15.9221 10.4763C15.9731 10.3574 15.9996 10.2294 16 10.1V6.20001C16.0017 5.09492 15.5671 4.03383 14.7907 3.24737C14.0144 2.46092 12.959 2.01265 11.854 2.00001C10.8264 2.04117 9.85379 2.47507 9.1367 3.21225C8.41962 3.94943 8.01275 4.93367 8 5.96201V10.1C7.99979 10.2266 8.0249 10.352 8.07384 10.4688C8.12278 10.5856 8.19458 10.6914 8.285 10.78L9.707 12.2C9.89455 12.3875 9.99994 12.6418 10 12.907V14.327C9.99724 14.9896 9.77432 15.6325 9.3663 16.1545C8.95827 16.6766 8.3883 17.0482 7.746 17.211C6.67872 17.4804 5.73137 18.0972 5.05318 18.9642C4.37498 19.8313 4.00447 20.8993 4 22Z" fill="black" />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect width={24} height={24} fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <div className="media-body ml-1">
                        <h3 className="mb-0 text-black font-w600 fs-20">Compartido conmigo</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 text-md-right">
                    {list !== null && list.permission === "E" &&
                      <>
                        <button onClick={() => handleshowModalCreate()} className="btn btn-outline-primary rounded btn-sm px-4">
                          Subir
                        </button>

                        <button onClick={() => handleShowModalFolder()} className="btn btn-danger rounded ml-2 btn-sm px-4">
                          Crear carpeta
                        </button>
                      </>
                    }
                  </div>
                </div>							
              </div>
            </div>
          </div>
        </div>
        {(!loading) 
        ?
          <div className="row">
            <div className="col-xl-12">	
              <div className="">
                <div>
                  <div className="table-responsive table-hover fs-14">
                    <div id="example5_wrapper" className="dataTables_wrapper no-footer"><table className="table display mb-4 dataTablesCard fs-14 dataTable no-footer" id="example5" role="grid" aria-describedby="example5_info">
                        <thead>
                          <tr role="row">
                            <th style={{width: 'auto'}} 
                            className="sorting_asc" 
                            tabIndex={0} 
                            aria-controls="example5" 
                            rowSpan={1} 
                            colSpan={1} 
                            aria-sort="ascending" 
                            aria-label="Archivo: activate to sort column descending">
                              Nombre
                            </th>
                            <th 
                            className="d-none text-center d-lg-table-cell sorting" 
                            tabIndex={0} 
                            aria-controls="example5" 
                            rowSpan={1} 
                            colSpan={1} 
                            aria-label="Categoría: activate to sort column ascending" 
                            style={{width: '71px'}}>
                              Categoría
                            </th>
                            <th 
                            className="text-center sorting" 
                            tabIndex={0} 
                            aria-controls="example5" 
                            rowSpan={1} 
                            colSpan={1} 
                            aria-label="Acción: activate to sort column ascending" 
                            style={{width: '568px'}}>
                              Acción
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colSpan="10">
                              {(errormessage !== '') &&
                                  <div className="mb-3 alert alert-danger">
                                      <p className="mb-0 text-center font-weight-bold">{errormessage}</p>
                                  </div>
                              }
                            </td>
                          </tr>

                          {(list !== null) &&
                            <>
                              { (list.folders && Array.isArray(list.folders) && list.folders.length === 0) &&
                                (list.files && Array.isArray(list.files) && list.files.length === 0) &&
                                  <Fragment>
                                    <tr>
                                      <td colSpan="4" className="text-center">
                                        Sin archivos encontrados
                                      </td>
                                    </tr>
                                  </Fragment>
                              }

                              {(list.folders && Array.isArray(list.folders)) &&
                                  <Fragment>
                                    {(list.folders.length > 0 && list.folders.map((item, key) => {
                                      //item = item.folder;
                                      let itempermissions = item.permissions;

                                      return (
                                        <tr key={key}>
                                          <td>
                                            <Link 
                                              to={"/compartido-conmigo/folder/"+idInPath+"/descendant/"+item.id}
                                              style={{width: 'auto'}} 
                                              className="media align-items-center item-click"
                                            >
                                              <img 
                                                className="img-fluid rounded mr-3 d-xl-inline-block" 
                                                width={70} 
                                                src={folderImg} 
                                                alt="Carpeta" 
                                              />
                                              <div className="media-body">
                                                <h4 className="text-black font-w600 mb-1 wspace-no">
                                                  {item.name}
                                                </h4>
                                              </div>
                                            </Link>
                                          </td>
                                          <td className="text-center">
                                            <div style={{width: '200px'}}>
                                              --
                                            </div>
                                          </td>
                                          <td>
                                            <div className="d-flex">
                                                <DataGroupButtons 
                                                  page="shared-folder-withme"
                                                  type="folder"
                                                  userPermissions={userPermissions} 
                                                  id={`btn-folder-shared-withme-${item.id}`} 
                                                  item={item}
                                                  showModal={showModal}
                                                  permission={itempermissions}
                                                />
                                              </div>
                                          </td>
                                        </tr>
                                      )
                                    }))}
                                  </Fragment>
                              }

                              {(list.files && Array.isArray(list.files)) &&
                                <Fragment>
                                  {(list.files.length > 0 && list.files.map((item, key) => {
                                    
                                    //item = item.file;
                                    let filename = item.name.split('.');
                                    let formattext = filename[filename.length - 1].toLowerCase();
                                    //console.log(formattext);

                                    let itempermissions = item.permissions;

                                    return (
                                      <tr key={key}>
                                        <td>
                                          <div 
                                            style={{width: 'auto'}} 
                                            className="media align-items-center"
                                          >
                                              {(item.thumbnail !== null && item.thumbnail !== '') 
                                              ?
                                                <img 
                                                  className="img-fluid rounded mr-3 d-xl-inline-block" 
                                                  width={70} 
                                                  src={formatUrl(item.thumbnail)} 
                                                  alt="Carpeta" 
                                                />
                                              :
                                                <div style={{ width: "70px", marginRight: "15px" }}>
                                                  <div style={{ width: "70px"}}></div>
                                                  <FileIcon radius={12} extension={formattext} {...defaultStyles[formattext]} />
                                                </div>
                                              }
                                            <div className="media-body">
                                              <NameComponent
                                                item={item} 
                                                id={item.id} 
                                                name={item.name} 
                                                status={item.status} 
                                                status_name={item.status_name} 
                                                selectItem={setfileSelected}
                                                showModalStatus={() => setShowModalStatus(true)}
                                              />
                                            </div>
                                          </div>
                                        </td>
                                        <td className="text-center">
                                          {item.category ? item.category.breadcrumb : "--"}
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-start">
                                            <DataGroupButtons 
                                              page="shared-folder-withme"
                                              type="file"
                                              userPermissions={userPermissions} 
                                              id={`btn-file-shared-folder-withme-${item.id}`} 
                                              item={item}
                                              permissions={item.permissions}
                                              setFolder={setfolderSelected}
                                              setFile={setfileSelected}
                                              showModal={showModal}
                                            />  
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  }))}    
                                </Fragment>
                              }
                            </>
                          }
                        </tbody>
                      </table>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        :
          <Loader />
        }

        <ModalUpdateStatus refresh={refresh}    file={fileSelected}                               show={showModalStatus} close={() => setShowModalStatus(false)} />

        <ModalDeleteFolder refresh={refresh}  folder={folderSelected}   isShare parent={idInPath} subfolder={idSubfolder} show={showModalDeleteFolder}  close={handleCloseModalDeleteFolder} />
      
        <ModalDeleteFile   refresh={refresh}  file={fileSelected}       isShare parent={idInPath} subfolder={idSubfolder} show={showModalDeleteFile}  close={handleCloseModalDeleteFile} />
        <ModalShowFile     refresh={refresh}  file={fileSelected}                                 show={showModalShowFile}       close={handleCloseModalShowFile} />

        <ModalRenameFile   refresh={refresh}  file={fileSelected}       isShare parent={idInPath} subfolder={idSubfolder} show={showModalRenameFile}     close={handleCloseModalRenameFile} />
        <ModalRenameFolder refresh={refresh}  folder={folderSelected}   isShare parent={idInPath} subfolder={idSubfolder} show={showModalRenameFolder}  close={handleCloseModalRenameFolder} />
        
        <ModalFolder       refresh={refresh}  folder={path}             isShare parent={idInPath} subfolder={idSubfolder} show={showModalFolder} close={handleCloseModalFolder} />
        
        {userPermissions.can_create_private_files &&
          <UploadFile refresh={refresh} folder={path} isShare parent={idInPath} subfolder={idSubfolder} open={showModalCreate}   close={handleCloseModalCreate} fixedCategory={false} />
        }

      </div>
    )
}

export default withRouter(FolderCompartido);
