import React, {useState, useEffect, useRef} from 'react'
import SelectRole from '../SelectRole';
import {useSelector} from "react-redux"
import SelectPermissions from '../SelectPermissions';
import axios from 'axios';
import DropdownSelect from '../DropdownSelect';

function StepTwo({ btnCancel, type = "files", id, usersList = [], isShared, share }) {

    const session = useSelector(state => state.session);

    const [loading,         setloading]         = useState(true);
    const [search,          setsearch]          = useState(true);

    const [adding,          setadding]          = useState(false);

    const [errormessage,    seterrormessage]   = useState('');

    //este almacena los ids
    const [usersInList,     setusersInList]    = useState([]);
    const [usersInSearch,   setusersInSearch]   = useState([])

    const [permissions,     setPermissions]    = useState(null);

    const [textsearch,      settextsearch]     = useState('');
    const [searching,       setsearching]      = useState(false);

    const [listSearch,      setlistSearch]     = useState([]);
    const [list,            setlist]           = useState(null);
    const [users,           setusers]          = useState(null)
    const [count,           setcount]          = useState(0);

    const [refresh,         setrefresh]        = useState(false);
    const [actualRole,      setactualRole]     = useState(null);

    const input = useRef(null);

    const searchUsersForShared = () => {
        setsearching(true);

        //let transformText = encodeURI(textsearch.trim().toLowerCase().replace(/\s+/gi,' '));
        let transformText = textsearch.trim().toLowerCase();

        let results = usersList.filter(item => {
            let allsearch = item.email+" "+item.username+" "+item.name;
            allsearch = allsearch.toLowerCase();

            return allsearch.includes(transformText);
        });

        console.log(results);
        setlistSearch(results);
        setsearching(false);

    }

    const getData = () => {
        let urlget = "";

        if(type === "folder"){
            urlget = "/filemanager/shared-folders/"+id+"/users/";
        }else{
            urlget = "/filemanager/shared-files/"+id+"/users/";
        }

        axios({
            url: urlget,
            method: "get"
        }).then((res) => {
            console.log(res);
            if(Array.isArray(res.data) && res.data.length > 0){
                let items = res.data;
                let ids   = [];
                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    ids.push(item.user.id);
                }

                console.log(ids);
                setusersInList(ids);
                setusers(items);
            }else{
                setusersInList([]);
                setusers(null);
            }

            setloading(false);
        }).catch((err) => {
            console.error(err);
        });
    }

    useEffect(() => {
        if(loading){
            if(search){
                setsearch(false);
                getData();
            }
        }
    });

    const addUserToItems = (item) => {
        seterrormessage("");

        let actualList      = list === null ? [] : list;
        let actualUsersList = usersInSearch === null ? [] : usersInSearch;

        if(!actualUsersList.includes(item.id)){
            let newUser = {
                data: item,
                permissions: {label: "Lector", value: "R"}
            }

            actualUsersList.push(item.id);
            actualList.push(newUser);

            setlistSearch([]);
            settextsearch("");
            setusersInSearch(actualUsersList);
            setlist(actualList);
            setcount(count + 5);
        }
    }

    const deleteUser = (row) => {
        let actualList = list === null ? [] : list;
        let listWithoutItem = actualList.filter(item => item.data.name !== row.data.name);
        if(usersInSearch.includes(row.data.id)){
            let newUsersList = usersInSearch.filter(item => item !== row.data.id);
            setusersInSearch(newUsersList);
            setlist(listWithoutItem);
            setcount(count + 5);
        }
    } 

    const changetext = (val) => {
        if(val !== ''){
            settextsearch(val);
            if(textsearch.length > 0){
                searchUsersForShared();
            }
        }else{
            setlistSearch([]);
            settextsearch(val);
        }
    }
    
    const focusInput = () => {
        console.log(input);
        input.current.focus();
    }

    const sendUser = () => {
        let url = "";

        if(type === "folder"){
            url = "/filemanager/shared-folders/"+id+"/add-users/";
        }else{
            url = "/filemanager/shared-files/"+id+"/add-users/";
        }

        axios({
            url,
            method: "POST",
            data: {
                users: usersInSearch,
                permission: permissions.value
            }
        }).then((res) => {

            console.log(res);
            setadding(false);
            setPermissions(null);
            setlist(null);
            settextsearch("");
            setusersInSearch([]);
            getData();

        }).catch((err) => {
            console.error(err);
        });
    }

    const addUsersToList = (e) => {
        e.preventDefault();
        setadding(true);

        if(isShared !== undefined && isShared){

            sendUser();

        }else if(isShared !== undefined && !isShared){

            let url = "";

            if(type === "folder"){
                url = "/filemanager/share-folder/"+id+"/";
            }else{
                url = "/filemanager/share-file/"+id+"/";
            }

            axios({
                method: "POST",
                url
            }).then((res) => {
                console.log(res.data);
                sendUser();
                share(true);
            }).catch((err) => {
                console.error(err);
                if(err.response){
                    if(err.response.data && err.response.data.hasOwnProperty("non_field_errors")){
                        //share();
                    }
                }
            });

        }
    }

    const setPermissionsInList = (iditem,iduser, value) => {
        let url = "";
        
        if(type === "folder"){
            url = "/filemanager/shared-folders/"+id+"/users/"+iduser+"/";
        }else{
            url = "/filemanager/shared-files/"+id+"/users/"+iduser+"/";
        }
 
        axios({
            url,
            method: "PUT",
            data: {
                permission: value.value
            }
        }).then((res) => {
            console.log(res);
            getData();
        }).catch((err) => {
            console.error(err);
        });
    }

    const deleteUserInList = (iduser) => {
        let url = "";

        if(type === "folder"){
            url = "/filemanager/shared-folders/"+id+"/users/"+iduser+"/";
        }else{
            url = "/filemanager/shared-files/"+id+"/users/"+iduser+"/";
        }

        axios({
            url,
            method: "DELETE",
        }).then((res) => {
            console.log(res);
            getData();
        }).catch((err) => {
            console.error(err);
        });
        
    }

    console.log(users);

    return (
        <div>
            <div className="">
                <div className="row">
                    <div className="col-lg-8 position-relative">
                        <div className="form-group">
                            <div onClick={() => focusInput()} className="input-search-multiple">
                                {list !== null && list.map((item, key) => {
                                    return (
                                        <div className="item-in-multiple" key={key}>
                                            {item.data.email}
                                            <button 
                                                onClick={() => deleteUser(item)} 
                                                className="btn ml-2 p-0 btn-sm"
                                            >
                                                <i className="fa fa-times text-danger"></i>
                                            </button>
                                        </div>
                                    );
                                })}
                                <input 
                                    ref={input}
                                    type="text" 
                                    value={textsearch}
                                    //onBlur={() => setlistSearch(null)}
                                    onChange={(e) => changetext(e.target.value)}
                                    //onFocus={() => changetext(textsearch)}
                                    placeholder="Buscar" 
                                />
                            </div>
                        </div>

                        {(searching || (!searching && Array.isArray(listSearch) && listSearch.length > 0)) &&
                            <div className="content-results-multiple">
                                {(searching) &&
                                    <div>
                                        <p className="mb-3 text-center">
                                            <i className="fa fa-spin fa-spinner fa2x"></i>
                                        </p>
                                    </div>
                                }   

                                {!searching && Array.isArray(listSearch) && listSearch.length > 0 &&
                                    <div className="listdata">
                                        <div className="table-responsive">
                                            <table className="table table-hover">
                                                <tbody>
                                                    {listSearch.map((item, key) => {
                                                        if(!usersInSearch.includes(item.id) && !usersInList.includes(item.id) && item.email !== session.userData.email){
                                                            return (
                                                                <tr onClick={() => addUserToItems(item)} key={key}>
                                                                    <td className="item-multisearch-list" style={{cursor:"pointer"}}>
                                                                        <p className="mb-0 p-0">
                                                                            {item.name} (<strong>{item.username}</strong>)
                                                                            <br/>
                                                                            <small>{item.email}</small>
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }else{
                                                            return false
                                                        }
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                            </div>
                        }

                    </div>
                    <div className="col-lg-2 col-6">
                        <SelectPermissions 
                            onChange={(value) => setPermissions(value)} 
                            value={permissions} 
                        />
                    </div>
                    <div className="col-lg-2 col-6">
                        <button 
                            disabled={permissions === null || usersInSearch.length === 0}
                            onClick={(e) => addUsersToList(e)} 
                            style={{fontSize: "5px !important"}} 
                            className="btn btn-block btn-sm btn-primary"
                        >
                            {adding ? <i className="fa fa-spin fa-spinner"></i> : "Agregar"}
                        </button>
                    </div>
                </div>
            </div>
            {(loading) &&
                <div className="py-3">
                    <p className="mb-3 text-center">
                        <i className="fa fa-spin fa-spinner fa3x"></i>
                    </p>
                </div>
            }   
            <div>
                {Array.isArray(users) && users.length > 0 &&
                    <div className="usersTable mt-3">
                        <div className="table-responsive">
                            <table className="table table-sm table-condensed table-bordered">
                                <thead>
                                    <tr>
                                        <th className="py-2">Nombre</th>
                                        <th className="py-2">Gmail</th>
                                        <th className="py-2">Permiso</th>
                                        <th className="py-2">
                                            <i className="fa fa-times"></i>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map((item, key) => {
                                        console.log(item.permission);

                                        return (
                                            <tr key={key}>
                                                <td className="py-0">
                                                    {item.user.name}
                                                </td>
                                                <td>
                                                    {item.user.email}
                                                </td>
                                                <td className="py-0">
                                                    <div style={{width: "140px"}}>
                                                        <SelectPermissions 
                                                            onChange={(value) => setPermissionsInList(item.id,item.user.id,value)} 
                                                            value={item.permission} 
                                                            normal={true}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="py-0">
                                                    <button onClick={() => deleteUserInList(item.user.id)} className="btn px-0 py-0">
                                                        <i className="fa text-danger fa-times"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }

                {(Array.isArray(users) && users.length === 0) || users === null &&
                    <div className="py-2">
                        <p className="mb-0 text-center">
                            Sin usuarios agregados
                        </p>
                    </div>
                }

            </div>
            <div className="text-right pt-3">
                {btnCancel}
            </div>
            <div className="pt-3">
                {errormessage !== "" &&
                    <div className="mb-3 alert alert-danger text-center">
                        <small>
                            {errormessage}
                        </small>
                    </div>
                }
            </div>
        </div>
    )
}

export default StepTwo
