import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Modal, ProgressBar} from "react-bootstrap"
import { set_download_list } from '../../store/dashboard/actions';

function DownloadFilesList() {

    const downloadList    = useSelector(state => state.dashboard.download_list);
    const dispatch        = useDispatch();

    const [error, seterror]     = useState(false);
    
    useEffect(() => {
        if(downloadList.length > 0){
            let progress = downloadList[0].progress;

            if(progress === 100){
                setTimeout(() => {
                    dispatch(set_download_list([]));
                }, 1500);
            }
        }
    });

    const cancelRequest = () => {
        downloadList[0].signal();
        seterror(true);

        setTimeout(() => {
            seterror(false);
            dispatch(set_download_list([]));
        }, 1500);
    }

    return (
        <Modal 
            show={downloadList.length > 0} 
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
            <Modal.Title>
                <i className="fa fa-spin fa-spinner d-none mr-3 text-primary"></i>
                Descargando archivo
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {downloadList.length > 0 && 
                    <div>
                        {!error ?
                        <div>
                            <p className="text-center">
                                Por favor espere mientras se completa su descarga.
                            </p>
                            {downloadList.length > 0 && 
                                <div className="text-center mb-4">
                                    {downloadList[0].progress < 100
                                    ?
                                        <i className="fa fa-spin fa-spinner fa-3x text-primary"></i>
                                    :
                                        <div className="text-center">
                                            <i className="fa fa-check-circle fa-3x text-success"></i>
                                            <div>
                                                Descarga completada
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            {downloadList.length > 0 && 
                                <div className="text-center">
                                    <ProgressBar variant={downloadList[0].progress < 100 ? "primary" : "success"} now={downloadList[0].progress} />
                                    {downloadList[0].progress < 100 &&
                                        <button className="btn btn-danger mt-3" onClick={() => cancelRequest()}>
                                            Cancelar
                                        </button>
                                    }
                                </div>
                            }
                        </div>
                        :
                        <div>
                            <div className="text-center">
                                <i className="fa fa-ban fa-3x text-danger"></i>
                                <div className="my-3">
                                    Descarga cancelada
                                </div>
                            </div>
                            <div className="text-center">
                                <ProgressBar variant="danger" now={downloadList[0].progress} />
                            </div>
                        </div>
                        }
                    </div>
                }
            </Modal.Body>
        </Modal>
    )
}

export default DownloadFilesList
