import {CHANGE_USER_DATA, LOGIN, LOGOUT, SET_PHOTO, SET_USER_DATA, SET_SETTINGS} from '../types'
import axios from 'axios';

export const handleLogin = () => {
    let url = '/accounts/me/';

    return async dispatch => {
        await axios
          .get(url)
          .then(res => {
            if(res.data){
                //console.log(res.data);
                dispatch({
                    type: LOGIN,
                    payload: res.data
                })
            }
          })
          .catch(err => console.log(err + "action"))
    }
}

export const handleLogout = () => {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common["Authorization"];

    return dispatch => {
        dispatch({
            type: LOGOUT
        });
    }
}

export const set_user_data = (data) => {
    return dispatch => {
        dispatch({
            type: SET_USER_DATA,
            payload: data
        });
    }
}

export const change_user_data = (data) => {
    console.log('Modificando datos personales');
    
    return dispatch => {
        dispatch({
            type: CHANGE_USER_DATA,
            payload: data
        });
    }
}

export const set_photo = (photo) => {
    return dispatch => {
        dispatch({
            type: SET_PHOTO,
            payload: photo
        });
    }
}

export const set_settings = () => {
    let url = '/accounts/list-settings/';

    return async dispatch => {
        await axios
          .get(url)
          .then(res => {
            if(res.data){
                console.log(res.data);
                dispatch({
                    type: SET_SETTINGS,
                    payload: res.data
                })
            }
          })
          .catch(err => console.log(err + "action"))
    }
}