import React, {useState, useEffect} from 'react'
import {Modal, Button, ProgressBar, Tabs ,Tab, Row, Col, Nav} from 'react-bootstrap'
import axios from 'axios'
import {useSelector} from "react-redux"
import { onlyTextAndNumbers } from '../../utils/inputs';
import CategoriesSelect from '../CategoriesSelect';

function ModalUpdateStatus(props) {

    const [showModal,       setshowModal]  = useState(false);

    const [sending, setsending]                         = useState(false);
    const [successmessage, setsuccessmessage]           = useState('');
    const [errormessage, seterrormessage]               = useState('');
    const [progress, setprogress]                       = useState(0);
    const [count,    setcount]                          = useState(0);
    const [file,   setfile]                             = useState(null);

    const [errors,   seterrors]                         = useState({});

    const [name, setname]                               = useState((props.file) ? props.file.name : '');
    const [format, setformat]                           = useState('');
    
    const [status, setstatus]                           = useState(null);

    const [categoriesSelected, setcategoriesSelected]   = useState(null);
    const [tabActive, settabActive]                     = useState('1');

    const statusList = useSelector(state => state.dashboard.status);

    useEffect(() => {
        //console.log(categoriesSelected);
        //console.log(props.file);

        if(props.show === true){
            if(!showModal){
                setshowModal(props.show);
                setsuccessmessage('');
                seterrormessage('');
                setprogress(0);
                setcount(0);
                
                if(props.type === "status"){
                    settabActive('1');
                }else if(props.type === "category"){
                    settabActive('2');
                }             
            }

            if(props.file !== file && props.file !== null && props.file !== undefined){
                console.log('Actualizando archivo');
                //console.log(props.file);

                setsending(false);
                
                setsuccessmessage('');
                seterrormessage('');
                setprogress(0);
                setcount(0);
                
                if(props.type === "status"){
                    settabActive('1');
                }else if(props.type === "category"){
                    settabActive('2');
                }   
    
                if(props.file.category !== null){
                    console.log(props.file.category);
                    setcategoriesSelected(
                        {
                            label: props.file.category.name, 
                            value: props.file.category.id
                        }
                    );
                }else{
                    setcategoriesSelected(null);
                }

                setfile(props.file);
                setstatus(props.file.status);

            } 

        }else{
            setfile(null);
            setshowModal(props.show);
        }
    });

    const updateStatus = (e) => {
        let url  = '';
        let data = null;
        let successmessagereturn = '';

        if(tabActive === '1'){
            if(props.file){
                url = '/filemanager/update-file-status/'+props.file.id+'/';
            }

            data = {
                status: status
            }

            successmessagereturn = 'Estatus modificado';

        }else if(tabActive === '2'){
            if(props.file){
                url = '/filemanager/update-file-category/'+props.file.id+'/';
            }

            data = {
                category: categoriesSelected !== null ? categoriesSelected.value : null
            }

            successmessagereturn = 'Categoría modificada';
        }
                
        seterrors({});
        setsending(true);
        setsuccessmessage('');
        seterrormessage('');  

        axios({
            url: url,
            method: 'put',
            data: data
        }).then((res) => {

            console.log(res.data);
            setsending(false);

            if(props.file){
                setsuccessmessage(successmessagereturn);
            }else{
                setsuccessmessage(successmessagereturn);
            }
 
            //refresh
            props.refresh();
            setTimeout(() => {
                props.close();
            }, 1000);

        }).catch((err) => {
            console.error(err);
            setsending(false);
            if(err.response.data){
                seterrors(err.response.data);
            }
            if(err.response.data.hasOwnProperty('non_field_errors')){
                seterrormessage(err.response.data.non_field_errors);
            }
        });
    }

    //console.log(file);

    return (
        <div>
            <Modal animation={false} show={showModal} onHide={props.close}>
                <Modal.Header closeButton>
                <Modal.Title>
                    <strong>Editar {props.file ? 'archivo' : 'archivo'}</strong>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(successmessage !== '') &&
                        <div className="mb-3 alert alert-success">
                            <p className="mb-0 font-weight-bold">{successmessage}</p>
                        </div>
                    }
                    {(errormessage !== '') &&
                        <div className="mb-3 alert alert-danger">
                            <p className="mb-0 font-weight-bold">{errormessage}</p>
                        </div>
                    }
                    { (progress > 0) &&
                        <div className="py-3">
                            <ProgressBar variant="success" animated now={progress} />
                        </div>
                    }

                    {(file !== null && successmessage === '') &&

                        <div>

                            <div className="row mb-3">
                                <div className="col-12">
                                    <label htmlFor="foldername">
                                        Archivo: <strong>{file.name}</strong>
                                        <span className="badge px-3 shadow-sm ml-2 badge-light">
                                            {file.status_name}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        {/* 
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={tabActive}
                                onSelect={(k) => settabActive(k)}
                                className="mb-3"
                            >
                                <Tab eventKey="1" title="Cambiar estatus">
                                    {(file !== null && successmessage === '') &&
                                        <div className="change-status-panel">
                                            <h6 className="font-weight-bold h5 mb-3">
                                                Estatus
                                            </h6>
                                            <div className="row">
                                                {(Array.isArray(statusList) && statusList.length > 0 && statusList.map((item, key) => {
                                                    return (
                                                        <div key={key} className="col-lg-4">
                                                            <label htmlFor={`status-${item.value}`}>
                                                                <input checked={status === item.value} onClick={() => setstatus(item.value)} id={`status-${item.value}`} name="status" type="radio" />
                                                                <span className="ml-2 text-dark font-weight-bold">
                                                                    {item.label}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    );
                                                }))}
                                            </div>
                                        </div>
                                    }
                                </Tab>
                                <Tab eventKey="2" title="Cambiar categoría">
                                    <h6 className="font-weight-bold h5 mb-3">
                                        Categoría
                                    </h6>
                                    <CategoriesSelect isFixed={false} value={categoriesSelected} onChange={(val) => setcategoriesSelected(val)} />
                                </Tab>
                            </Tabs>
*/}
                            {(props.type === "status") &&
                                <div>
                                    {(file !== null && successmessage === '') &&
                                        <div className="change-status-panel">
                                            <h6 className="font-weight-bold h5 mb-3">
                                                Estatus
                                            </h6>
                                            <div className="row">
                                                {(Array.isArray(statusList) && statusList.length > 0 && statusList.map((item, key) => {
                                                    return (
                                                        <div key={key} className="col-lg-4">
                                                            <label htmlFor={`status-${item.value}`}>
                                                                <input checked={status === item.value} onClick={() => setstatus(item.value)} id={`status-${item.value}`} name="status" type="radio" />
                                                                <span className="ml-2 text-dark font-weight-bold">
                                                                    {item.label}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    );
                                                }))}
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {(props.type === "category") &&
                                <div>
                                     <h6 className="font-weight-bold h5 mb-3">
                                        Categoría
                                    </h6>
                                    <CategoriesSelect isFixed={false} value={categoriesSelected} onChange={(val) => setcategoriesSelected(val)} />
                                </div>
                            }

                        </div>
                    }
                
                </Modal.Body>
                <Modal.Footer>
                    {(file !== null) &&
                        <Button disabled={sending || successmessage !== ''} onClick={(e) => updateStatus(e)} variant="primary" type="button" >
                            {(sending) 
                            ? 
                                <span>
                                    <i className="fa fa-spinner fa-spin mr-2"></i>Cargando
                                </span> 
                            : 
                                <span>
                                    Editar 
                                    {tabActive === '1' ? ' estatus' : ''}
                                    {tabActive === '2' ? ' categoría' : ''}
                                </span>
                            }
                        </Button>
                    }
                    <Button variant="light" onClick={props.close}>
                        <i className="fa fa-times mr-2"></i>Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalUpdateStatus
