import React, {useRef, useState} from 'react'

function FileInput(props) {

    let valuefiles = props.value !== null ? props.value : [];
    const [files, setfiles]     = useState(null);
    const inputfile             = useRef();

    const inputfileclick = () => {
        //console.log(inputfile);
        inputfile.current.click();
    }

    const inputChange = (e) => {
        let inputfiles = e.target.files;
        if(inputfiles && inputfiles.length > 0){
            props.onChange(inputfiles);
            setfiles(inputfiles);
        }
    }

    return (
        <div className="fileinput">
            <input 
                onChange={(e) => inputChange(e)} 
                type="file" 
                id="inputfileActionBox" 
                className="inputfile-action-box" 
                ref={inputfile} 
            />
            <div className="row">
                <div className="col-12 col-md-8">
                    <input 
                        className="form-control" 
                        type="text" readOnly 
                        placeholder={(valuefiles !== null && valuefiles.length > 0) ? valuefiles.length+' archivo(s) seleccionado(s)' : 'Sin archivo(s) seleccionado(s)'}
                    />
                </div>
                <div className="col-12 col-md-4 text-right">
                    <button type="button" onClick={() => inputfileclick()} className="btn btn-block btn-sm btn-primary">Subir archivos</button>
                </div>
            </div>
        </div>
    )
}

export default FileInput
