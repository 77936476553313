export const LOGIN              = 'LOGIN';
export const LOGOUT             = 'LOGOUT';

export const RESET_DASHBOARD    = 'RESET_DASHBOARD';

export const SET_PHOTO          = 'SET_PHOTO';

export const SET_SYMBOLIC_LINKS = 'SET_SYMBOLIC_LINKS';
export const SET_CATEGORIES     = 'SET_CATEGORIES';
export const SET_TAGS           = 'SET_TAGS';
export const SET_STATUS         = 'SET_STATUS';
export const SET_USER_DATA      = 'SET_USER_DATA';
export const SET_DOWNLOAD_LIST  = 'SET_DOWNLOAD_LIST';

export const CHANGE_USER_DATA   = 'CHANGE_USER_DATA';

export const SET_SETTINGS       = 'SET_SETTINGS';
