import {SET_CATEGORIES, SET_TAGS, SET_STATUS, RESET_DASHBOARD, SET_SYMBOLIC_LINKS, SET_DOWNLOAD_LIST} from '../types'

const initialState = {
    categories:     null,
    tags :          null,
    status:         null,
    symbolic_links: null,
    download_list:  []
}

const dashboardreducer = (state=initialState, action) => {
    switch (action.type) {
        case SET_CATEGORIES:
            return{
               ...state,
               categories: action.payload
            };

        case SET_TAGS:
            return{
                ...state,
                tags: action.payload
            };

        case SET_SYMBOLIC_LINKS:
            return{
                ...state,
                symbolic_links: action.payload
            }

        case SET_STATUS:
            return{
                ...state,
                status: action.payload
            };
        

        case SET_DOWNLOAD_LIST:
            return{
                ...state,
                download_list: action.payload
            };
        
        
        case RESET_DASHBOARD:
            return{
                ...state,
                categories: null,
                tags :      null,
                status:     null,
                symbolic_links: null,
                download_list:  []
            };
        
    
        default: return state;
    }
}

export default dashboardreducer;