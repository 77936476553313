import React, {useState, useEffect} from 'react'
import {
    Modal,
    Button,
    ProgressBar
} from 'react-bootstrap'
import axios from 'axios'
import { onlyTextAndNumbers } from '../utils/inputs';

function ModalCreateSubCategory(props) {

    const [showModal,       setshowModal]               = useState(false);

    const [sending, setsending]                         = useState(false);
    const [successmessage, setsuccessmessage]           = useState('');
    const [errormessage, seterrormessage]               = useState('');
    const [progress, setprogress]                       = useState(0);
    const [count,    setcount]                          = useState(0);
    const [errors,   seterrors]                         = useState({});

    const [name, setname]                               = useState((props.file) ? props.file.name : '');
    const [categoryId, setcategoryId]                   = useState(null);
    const [subcatSelected, setsubcatSelected]           = useState(null);

    const validate = () => {
        //let errors = {};
        let countErrors = 0;
        if(props.file && props.file !== null){
            if(name !== ""){
                seterrormessage('Debe ingresar un nombre para la sub-categoría');
                countErrors++;
            }
        }

        if(countErrors > 0){
            return false;
        }

        return true;
    }

    useEffect(() => {
        if(props.show === true){
            if(!showModal){
                setshowModal(props.show);
                setcategoryId(props.categoryId);
                setsuccessmessage('');
                seterrormessage('');
                setprogress(0);
                setcount(0);
                setname('');

                if(props.rename){
                    if(props.subcat){
                        console.log("category selected:", props.subcat);
                        setsubcatSelected(props.subcat);
                        setname(props.subcat.name);
                    }
                }else{
                    setsubcatSelected(null);
                }

                console.log("Crear sub categoria:" + props.categoryId);
            }
        }else{
            setshowModal(props.show);
        }
    });

    const createFolder = (e) => {
        let url = '';

        let isvalid = validate();

            if(isvalid){
                
            seterrors({});
            setsending(true);
            setsuccessmessage('');
            seterrormessage('');  
            
            if(props.rename){
                url     = `/filemanager/categories/rename/${subcatSelected.id}/`;
                axios({
                    url: url,
                    method: 'put',
                    data: {
                        name: name
                    }
                }).then((res) => {

                    console.log(res.datadata);
                    setsending(false);
                    setname('');
                    
                    setsuccessmessage('Sub-categoría renombrada correctamente');
                    
                    //refresh
                    props.refresh();
                    setTimeout(() => {
                        props.close();
                    }, 1000);

                }).catch((err) => {

                    console.error(err);
                    setsending(false);

                    let errors = err.response;
                    if(errors.hasOwnProperty("data")){
                        seterrors(errors.data);

                        if(errors.data.hasOwnProperty('non_field_errors')){
                            seterrormessage(err.response.data.non_field_errors);
                        }else if(err.response.data.hasOwnProperty('name')){
                            seterrormessage(err.response.data.name);
                        }
                    }
                });
            }else{
                url     = `/filemanager/categories/create/${categoryId}/`;
                axios({
                    url: url,
                    method: 'post',
                    data: {
                        name: name
                    }
                }).then((res) => {

                    console.log(res.datadata);
                    setsending(false);
                    setname('');
                    setsuccessmessage('Sub-categoría creada correctamente');
                    
                    //refresh
                    props.refresh();
                    setTimeout(() => {
                        props.close();
                    }, 1000);

                }).catch((err) => {

                    console.error(err);
                    setsending(false);

                    let errors = err.response;
                    if(errors.hasOwnProperty("data")){
                        seterrors(errors.data);

                        if(errors.data.hasOwnProperty('non_field_errors')){
                            seterrormessage(err.response.data.non_field_errors);
                        }else if(err.response.data.hasOwnProperty('name')){
                            seterrormessage(err.response.data.name);
                        }
                    }
                });
            }
        }
    }

    return (
        <div>
            <Modal animation={false} show={showModal} onHide={props.close}>
                <Modal.Header closeButton>
                <Modal.Title>
                    {props.rename ? "Renombrar " : "Crear "} sub-categoría
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(successmessage !== '') &&
                        <div className="mb-3 alert alert-success">
                            <p className="mb-0 font-weight-bold">{successmessage}</p>
                        </div>
                    }
                    {(errormessage !== '') &&
                        <div className="mb-3 alert alert-danger">
                            <p className="mb-0 font-weight-bold">{errormessage}</p>
                        </div>
                    }
                    { (progress > 0) &&
                        <div className="py-3">
                            <ProgressBar variant="success" animated now={progress} />
                        </div>
                    }

                    <div className="file-creator">
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="foldername">
                                    Nombre la sub-categoría
                                </label>
                                <div>
                                    <input 
                                        value={name}
                                        onKeyPress={(e) => onlyTextAndNumbers(e)}
                                        onChange={(e) => setname(e.target.value)}
                                        placeholder="Nombre la sub-categoría"
                                        type="text" 
                                        className="form-control"
                                    />
                                </div>
                                
                                {errors.hasOwnProperty('name') && Array.isArray(errors.name) &&
                                    <div className="help-block d-none text-danger font-weight-bold">
                                        <p className="mb-0 py-2">
                                            <i className="fa fa-exclamation-triangle mr-2"></i>
                                            {errors.name[0]}
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={sending} onClick={(e) => createFolder(e)} variant="primary" type="button" >
                        {(sending) 
                        ? 
                            <span>
                                <i className="fa fa-spinner fa-spin mr-2"></i>
                                Cargando
                            </span> 
                        : 
                            <span>
                                {props.rename ? "Renombrar " : "Crear "} sub-categoría
                            </span>
                        }
                    </Button>
                    <Button variant="light" onClick={props.close}>
                        <i className="fa fa-times mr-2"></i>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalCreateSubCategory
