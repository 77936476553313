import React, {useState, useEffect} from 'react'
import axios from "axios"
import {OverlayTrigger, Tooltip} from "react-bootstrap"
import {withRouter} from "react-router-dom"
import { FileIcon, defaultStyles } from 'react-file-icon';
import InlineLoader from '../../../../helpers/InlineLoader';
import {useSelector, useDispatch} from 'react-redux'
import { downloadURI, formatUrl, downloadFileFromUrl, downloadFunction } from '../../../../utils/urls';

function File(props) {
    let uuid = props.match.params.uuid;
    let url  = "/filemanager/shared-file/";

    const [iserror, setiserror] = useState(false);
    const [loading, setloading] = useState(true);
    const [search,  setsearch]  = useState(true);
    const [file,    setfile]    = useState(null);

    const dispatch        = useDispatch();
    const downloadList    = useSelector(state => state.dashboard.download_list);
    console.log("Lista de descargas:",downloadList);

    useEffect(() => {
        if(loading){
            if(search){
                setsearch(false);
                axios.get(url+uuid).then((res) => {
                    console.log(res.data);
                    setfile(res.data);
                    setloading(false);
                }).catch((err) => {
                    console.error(err);
                    setiserror(true);
                    setloading(false);
                })
            }
        }
    }, []);

    if(iserror){
        //error
        return (
            <div>
                <h1 className="font-weight-bold mb-3 h3">
                    Archivo compartido
                </h1>
                <div className="card">
                    <div className="card-body">
                        <h3 className="text-center mb-0">
                            <i className="fa fa-exclamation-triangle mr-3"></i> Archivo no encontrado.
                        </h3>
                    </div>
                </div>
            </div>
        )
    }else{
        if(loading){
            //cargando
            return (
                <div>
                    <InlineLoader />
                </div>
            )
        }else{
            //data cargada
            let item = file;
            let filename = item.name.split('.');
            //let categories = item.category.breadcrumb.split(">");
            let formattext = filename[filename.length - 1].toLowerCase();

            return (
                <div>
                    <h1 className="font-weight-bold mb-3 h3">
                        Archivo compartido
                    </h1>
                    <div className="card">
                        <div className="card-body p-4">
                            {(item.hasOwnProperty("folder") && item.folder !== null) &&
                                <div>
                                    <p>
                                        <i className="fa fa-folder mr-2"></i> {item.folder.name}
                                    </p>
                                </div>
                            }
                            {(item.hasOwnProperty("category") && item.category !== null) &&
                                <div>
                                    <p className="small">
                                        <i className="fa fa-list mr-2"></i> 
                                        <strong className="mr-3">Categoría:</strong> {item.category.breadcrumb}
                                    </p>
                                </div>
                            }
                            <div className="header-preview mb-3">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <div style={{ width: "50px", marginRight: "20px" }}>
                                            <div style={{ width: "50px"}}></div>
                                            {(item.thumbnail !== null && item.thumbnail !== '') 
                                                ?
                                                <img 
                                                    className="img-fluid rounded mr-3 d-xl-inline-block" 
                                                    width={70} 
                                                    src={formatUrl(item.thumbnail)} 
                                                    alt="Carpeta" 
                                                />
                                                :
                                                <FileIcon radius={12} extension={formattext} {...defaultStyles[formattext]} />
                                            }
                                        </div>
                                    </div>
                                    <div className="col">
                                        <h6>Nombre:</h6>
                                        <div className="d-flex align-items-center">
                                            <h3 className="font-weight-bold mr-3 d-inline-block mb-0">
                                                {filename[0]}
                                            </h3>
                                            <span className="badge small badge-info font-weight-normal px-3">Compartido</span> 
                                        </div>
                                    </div>
                                    <div className="col-lg-auto">
                                        <h6>Creador:</h6>
                                        <p className="mb-0">
                                            {item.owner.name} - (<i className="fa fa-envelope mr-2"></i>{item.owner.email})
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <h6>Url:</h6>
                            <p className="mb-3 text-primary">
                                {formatUrl(item.file)}
                            </p>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                <Tooltip id={`tooltip-btn-dowload-file-public-file-${item.id}`}>
                                    Descargar
                                </Tooltip>
                                }
                            >
                                <button 
                                onClick={(e) => downloadFileFromUrl(e, formatUrl(item.file), item.name, downloadFunction, item, dispatch, downloadList)}
                                    className="py-2 h-auto btn btn-info btn_off btn-sm px-4">
                                    <i className="fa fa-download d-block" />
                                    <span className="d-none">Descargar</span>
                                </button>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default withRouter(File);
